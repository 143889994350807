import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import profileICO from "../user_admin.png";
import mobileICO from "../user_calls-contacts.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import emailICO from "../user_mail-new.png";
import Button from "react-bootstrap/Button";
import searchWhite from "../searchWhite.png";
import Contact from "./contact";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require("../config.json");
const cookies = new Cookies();

const options = [
  { value: "Building Admin and Reception User", label: "Building Admin and Reception User" },
  { value: "Security User", label: "Security User" },
  { value: "Administrator", label: "Administrator" },
  { value: "Super User", label: "Super User" },
];

//isDisabled: {this.state.isAdmin}

var generatePassword = require("password-generator");
var maxLength = 18;
var minLength = 12;
var uppercaseMinCount = 3;
var lowercaseMinCount = 3;
var numberMinCount = 2;
var specialMinCount = 2;
var UPPERCASE_RE = /([A-Z])/g;
var LOWERCASE_RE = /([a-z])/g;
var NUMBER_RE = /([\d])/g;
var SPECIAL_CHAR_RE = /([\?\-])/g;
var NON_REPEATING_CHAR_RE = /([\w\d\?\-])\1{2,}/g;

let sCountry = "";

class adminuseredit extends Component {
  constructor(props, context) {
    super(props, context);

    this.onSelectAdminUsers = this.onSelectAdminUsers.bind(this);
    this.searchContactsClick = this.searchContactsClick.bind(this);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/",
      showVisitorDontExist: "none",
      showVisitorExist: "none",
      countryDisabled: true,
      regionDisabled: true,
      errMsg_role: "none",
      errMsg_notadmin: "none",
      errMsg_country: "none",
      errMsg_region: "none",
      inputValue: "",
      contactName: "",
      searchState: '',
      showFields: 'none',
      contactsShow: 'none',
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      role: '',
      countryId: "",
      region: '',
      countryOptions: [],
      regionOptions: []
    };
  }

  onAddVisitorNameChange = event => this.setState({ firstname: event.target.value });
  onAddVisitorLastnameChange = event => this.setState({ lastname: event.target.value });
  onAddVisitorIdChange = event => this.setState({ id: event.target.value });
  onAddVisitorEmailChange = event => this.setState({ email: event.target.value });
  onAddVisitorMobileChange = event => this.setState({ mobile: event.target.value });

  onAddVisitorRoleChange = event => {
    this.setState({ role: event.value });
    this.setState({ errMsg_role: "none" });
    this.setState({ errMsg_country: "none" });
    this.setState({ errMsg_region: "none" });
    this.setState({ errMsg_notadmin: "none" });
    if (event.value === "Building Admin and Reception User") {

      if (this.state.userRole === "Administrator") {
        this.getAllCountries();
        this.setState({ countryDisabled: false });
        this.setState({ regionDisabled: false });
      } else {
        this.setDefaultCountry();
        this.setState({ regionDisabled: false });
        // this.getAllCountries();
        // this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) })
        // sCountry = this.state.countryId;
        this.setState({ region: "" });
        this.getAllRegions();
      }
    } else if (this.state.userRole === "Administrator") {
      this.getAllCountries();
      this.setState({ countryDisabled: false });
      this.setState({ regionDisabled: true });
    } else if (this.state.userRole === "Super User") {
      this.setDefaultCountry();
      this.setState({ countryDisabled: true });
    } else {
      this.setState({ countryDisabled: true });
      this.setState({ regionDisabled: true });
    }
  }

  onCountryChange = event => {
    sCountry = event.value;
    let obj = { value: event.value, label: event.label };
    this.setState({ countryId: obj }, () => {
      // Do an API call with this.state.searchTerm

    });
    // console.log(obj);
    // console.log(sCountry);
    this.getAllRegions();
  }

  onRegionChange = event => {
    this.setState({ region: event.value });
    this.setState({ errMsg_region: "none" });
    // console.log(this.state.region);
  }

  setDefaultCountry() {
    sCountry = this.state.adminCountry;
    // console.log(sCountry);

    let obj = { value: this.removeWhiteSpace(sCountry), label: sCountry };
    this.setState({ countryId: obj }, () => {
      // Do an API call with this.state.searchTerm

    });
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  isStrongEnough(password) {
    var uc = password.match(UPPERCASE_RE);
    var lc = password.match(LOWERCASE_RE);
    var n = password.match(NUMBER_RE);
    var sc = password.match(SPECIAL_CHAR_RE);
    var nr = password.match(NON_REPEATING_CHAR_RE);
    return password.length >= minLength &&
      !nr &&
      uc && uc.length >= uppercaseMinCount &&
      lc && lc.length >= lowercaseMinCount &&
      n && n.length >= numberMinCount &&
      sc && sc.length >= specialMinCount;
  }

  customPassword() {
    var password = "";
    var randomLength = Math.floor(Math.random() * (maxLength - minLength)) + minLength;
    while (!this.isStrongEnough(password)) {
      password = generatePassword(randomLength, false, /[\w\d\?\-]/);
    }
    return password;
  }

  searchContactsClick() {
    //alert("searchContactsClick");   
    this.setState({ searchState: "Searching..." });
    this.setState({ loadingimg: "none" }, function () {
      this.setState({ loadingimg: "none" });
      this.searchContacts();
    });
  }

  searchContacts = async () => {
    this.setState({ contactsShow: 'none' });
    this.setState({ contacts: null });

    // const headers = {
    //   "Access-Control-Allow-Origin": "*",
    //   "Content-Type": "application/json",
    // };

    // await axios
    //   .post(
    //     "https://engageplatform.vodacom.co.za/web/contacts",
    //     {
    //       auth_token: `${config.api.auth_token}`,
    //       name: this.state.inputValue,
    //     },
    //     { headers }
    //   )
    //   .then((response) => {
    //     console.log("Success ========>", response);
    //     this.setState({ contacts: response.data });
    //     if (response.data.length === 0) {
    //       this.setState({ contactsShow: 'none' });
    //       this.setState({ searchState: "No search results found, please try again." });
    //     } else {
    //       this.setState({ searchState: "" });
    //       this.setState({ contactsShow: 'block' });
    //     }
    //     this.setState({ loadingimg: "hidden" });
    //   })
    //   .catch((error) => {
    //     console.log("Error ========>", error);
    //   });
  };

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value,
    });
  }

  onSelectAdminUsers() {
    this.setState({ redirectTo: "/adminUsers" });
    this.setState({ redirect: true });
  }

  handleSelectContact = (name, surname, mail, mobile, photo) => {

    this.setState({ firstname: name });
    this.setState({ lastname: surname });
    this.setState({ mobile: mobile });
    this.setState({ email: mail });

    this.setState({ show: false });
    this.setState({ contactsShow: 'none' });
    this.setState({ showFields: 'block' });
    this.setState({ inputValue: '' });
  };

  handleAddUser = async (id, event) => {
    event.preventDefault();
    let formValid = true;

    if ((this.state.role === 'Administrator') && (this.state.userRole !== 'Administrator')) {
      this.setState({ errMsg_notadmin: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_notadmin: "none" });
    }

    if (this.state.role === '') {
      this.setState({ errMsg_role: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_role: "none" });
    }

    if (this.state.countryDisabled === false) {
      if (this.state.countryId === '') {
        this.setState({ errMsg_country: "block" });
        formValid = false;
      } else {
        this.setState({ errMsg_country: "none" });
      }
    }

    if (this.state.regionDisabled === false) {
      if (this.state.region === '') {
        this.setState({ errMsg_region: "block" });
        formValid = false;
      } else {
        this.setState({ errMsg_region: "none" });
      }
    }

    //Generate Password - or maybe option to add own?
    const newPassword = this.customPassword();

    // bcrypt.genSalt(10, (err, salt) => {
    //   bcrypt.hash(plaintextPassword, salt, function(err, hash) {
    //       // Store hash in the database
    //   });
    // })

    // bcrypt.compare(plaintextPassword, hash, function(err, result) {
    //   if (result) {
    //      // password is valid
    //   }
    // });

    if (formValid === true) {
      // Get Buildings for the selected region
      let buildings = " ";
      if (this.state.role === "Building Admin and Reception User") {
        try {
          const responseBuildings = await axios.get(`${config.api.invokeUrl}/buildings/` + this.state.region, {
            "headers": {
              "x-api-key": `${config.api.xapikey}`
            }
          });
          let buildingsData = responseBuildings.data;

          // buildingsData.map((bld, index) => buildings = buildings + ' ' + bld.name + ','); 

          buildingsData.map((item, i) => {
            if (item.buildingname != 'undefined') {
              buildings = buildings + ' ' + item.buildingname + ','
            }
          });

          buildings = buildings.replaceAll(' undefined,', '');
          buildings = buildings.replaceAll(' undefined ,', '');
          buildings = buildings.replaceAll('undefined,', '');

        } catch (err) {
          console.log(`An error has occurred: ${err}`);
        }
      }

      // add call to AWS API Gateway add product endpoint here        
      try {
        const params = {
          "id": this.state.email.toLowerCase(),
          "username": this.state.email.toLowerCase(),
          "password": newPassword,
          "email": this.state.email.toLowerCase(),
          "mobile": this.state.mobile,
          "country": this.removeWhiteSpace(sCountry),
          "buildingsregion": this.state.region,
          "name": this.state.firstname,
          "lastname": this.state.lastname,
          "userrole": this.state.role,
          "buildings": buildings,
          "prevpass1": newPassword
        };

        // console.log(params);
        await axios.post(`${config.api.invokeUrl}/adminusers/{id}`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        if (this.state.role === "Building Admin and Reception User") {
          this.sendEmailMessage(newPassword);
        }
        this.setState({ redirectTo: "/adminUsers" });
        this.setState({ redirect: true });

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    }
  }

  componentDidMount() {
    // this.getAllCountries();

    // console.log(this.state.userRole);
    if (this.state.userRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }

    // if (this.state.userRole !== "Administrator") {   
    //   sCountry = this.removeWhiteSpace(this.state.adminCountry);
    //   this.setState({ countryDisabled: true });
    //   this.setState({ countryId: sCountry });
    //   this.getAllRegions();
    // }


    // console.log(this.state.userRole);
    // if (this.state.userRole === "Administrator") {
    //   this.setState({ isAdminRole: true });
    // } else if (this.state.userRole !== "Administrator") {   
    //   // this.getAllCountries();
    //   this.setState({ country: this.removeWhiteSpace(this.state.adminCountry) });
    //   sCountry = this.state.country;
    //   this.setState({ region: "" });
    //   this.getAllRegions();
    // }
    // isOptionDisabled(option: OptionType, selectValue: OptionsType): boolean { 
    //   debugger; 
    //   return typeof this.props.isOptionDisabled === 'function' ? this.props.isOptionDisabled(option, selectValue) : (typeof this.option.disabled !== 'undefined' ? this.option.disabled : false );

  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      const countrydata = response.data;

      const options = countrydata.map(d => ({
        "value": d.Id,
        "label": d.countryname
      }))

      // console.log(options);

      this.setState({ countryOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  getAllRegions = async () => {
    try {
      const param = this.removeWhiteSpace(sCountry);
      // console.log(param);

      if (param !== '') {
        const response = await axios.get(`${config.api.invokeUrl}/region/` + param, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        // console.log(response.data);
        const regiondata = response.data;

        const options = regiondata.map(d => ({
          "value": d.Id,
          "label": d.regionname
        }))

        // console.log(options);

        this.setState({ regionOptions: options })
      }


      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  }

  sendEmailMessage = (newPassword) => {
    const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    const emailBody = "<p>Dear " + this.state.firstname + " " + this.state.lastname + "<p /><span>Please be advised that you have been assigned a building admin role for the Reception Interface.</span><br/><p><span>Your username is: </span><b>" + this.state.email.toLowerCase() + "</b><br/><span>Your password is: </span><b>" + newPassword + "</b><br /><p><span>Kind Regards</span><br/><span>National Facilities</span></p>";

    var data = JSON.stringify(
      {
        "apiKey": "ea46673c4c890903a6c7c328568ca326",
        "message": emailBody,
        "html": "True",
        "to": [this.state.email.toLowerCase()],
        "subject": "Building Admin",
        "from": "visitors@vodacom.co.za"
      });

    var config = {
      method: 'post',
      url: 'https://engageplatform.vodacom.co.za/web/email',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Grant User Access</h5>
            <br></br>
            <div className="form-group">
              <span style={{ margin: '0px 10px 0px 0px' }}>Create user</span>
              <input
                name="searchHost"
                id="searchHost"
                type="text"
                placeholder="Search name"
                className="form-control+matching-text-style"
                style={{ width: "400px", margin: "0px 10px 0px 0px" }}
                value={this.state.inputValue}
                onChange={(evt) => this.updateInputValue(evt)}
              />
              <Button variant="danger" onClick={this.searchContactsClick} style={{ width: "40px", height: "30px", margin: "-10px 0px 0px 0px" }}>
                <img
                  src={searchWhite}
                  onClick={this.searchContactsClick}
                  style={{
                    width: "20px",
                    padding: "0px 0px 0px 0px",
                    margin: "-10px 0px 0px 0px",
                    cursor: "pointer",
                  }} />
              </Button>
            </div>
            <div className="form-group">
              <span style={{ color: "red" }}>{this.state.searchState}</span>
            </div>
            <div className="rcorners" style={{ display: this.state.contactsShow, margin: '0px 0px 0px 90px' }}>
              <div style={{ height: "300px", overflowY: "scroll" }}>
                <div className="tile is-4 is-parent  is-vertical" >
                  {this.state.contacts && this.state.contacts.length > 0 ? (
                    this.state.contacts.map((contact) => (
                      <Contact
                        handleSelectContact={this.handleSelectContact}
                        mail={contact.mail}
                        name={contact.firstname}
                        surname={contact.surname}
                        mobile={contact.mobile}
                        photo={contact.photo}
                        key={contact.mail}
                      />
                    ))
                  ) : (
                    <div className="tile notification is-warning">
                      <p></p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>
              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={event => this.handleAddUser(this.state.email, event)}
                noValidate>
                <div className="container">
                  <div className="form-row">
                    <img src={profileICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder="First Name"
                      className="form-control+matching-text-style"
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.firstname}
                      readOnly
                    />
                  </div>

                  <div className="form-row">
                    <img src={profileICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="surname"
                      id="surname"
                      type="text"
                      placeholder="Last Name"
                      className="form-control+matching-text-style"
                      required
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.lastname}
                      readOnly
                    />
                  </div>

                  <div className="form-row">
                    <img src={emailICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="Email"
                      id="Email"
                      type="email"
                      placeholder="Email Address"
                      className="form-control+matching-text-style"
                      value={this.state.email}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      readonly
                    />
                  </div>

                  <div className="form-row">
                    <img src={mobileICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="Mobile"
                      id="Mobile"
                      type="text"
                      placeholder="Contact Number"
                      className="form-control+matching-text-style"
                      value={this.state.mobile}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      readonly
                    />
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={network} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={options}
                        placeholder="Select role "
                        onChange={this.onAddVisitorRoleChange}
                        required
                        styles={style}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_role, color: 'red' }}>Please complete.</span>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_notadmin, color: 'red' }}>You do not have enough privilege to choose this option.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.countryDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select country "
                        onChange={this.onCountryChange}
                        required
                        styles={style}
                        options={this.state.countryOptions}
                        value={this.state.countryId}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.regionDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select region "
                        onChange={this.onRegionChange}
                        required
                        styles={style}
                        options={this.state.regionOptions}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_region, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/adminUsers/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>

                </div>
                <div className="container">

                </div>
              </FormWithConstraints>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default adminuseredit;