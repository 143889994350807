import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import { Link } from "react-router-dom";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import region from "../location_white.png";
import Select from "react-select";
import { Redirect } from 'react-router';
import plus from "../plus.png";
import CountryComponent from "./countryComponent";
import Modal from "react-bootstrap/Modal";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const config = require('../config.json');
const cookies = new Cookies();
let data = [];
let countryId = "";

class countries extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDeleteCountry = this.handleDeleteCountry.bind(this);
    this.handleEditCountry = this.handleEditCountry.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRegionClose = this.handleRegionClose.bind(this);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      redirect: false,
      redirectTo: "/",
      errMsg_region: "none",
      showDeleteMessage: false,
      showRegionMessage: false,
      deleteId: "",
      editId: ""
    };
  }

  handleClose() {
    this.setState({ showDeleteMessage: false });
  }

  handleRegionClose() {
    this.setState({ showRegionMessage: false });
  }

  handleDeleteCountry = (country, event) => {
    event.preventDefault();
    // alert(countryId);
    countryId = country;
    this.setState({ deleteId: countryId });
    this.checkRegionsExist();

  }

  // handleDeleteCountry() {
  //   // alert(this.state.deleteId);
  //   this.deleteCountry();
  // }

  deleteCountry = async () => {
    try {
      const id = countryId; //.toLowerCase();

      await axios.delete(`${config.api.invokeUrl}/country/` + id, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      this.getAllCountries();
      this.setState({ deleteId: "" });
      this.setState({ showDeleteMessage: false });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleEditCountry = (countryId, name, event) => {
    event.preventDefault();
    this.setState({ editId: countryId });
    this.setState({ name: name });
    this.setState({ redirect: true });
    this.setState({ redirectTo: "/countryEdit" })
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      this.setState({ setTickets: response.data });
      data = response.data;
      //  console.log(this.setTickets);
      this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("An error occured while getting country data");
    }
  };

  checkRegionsExist = async () => {
    try {

      // const id = countryId;

      if (countryId !== '') {
        const response = await axios.get(`${config.api.invokeUrl}/region/` + countryId, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        // console.log(countryId);
        // console.log(response.data);

        if (response.data.length === 0) {
          this.setState({ showDeleteMessage: true });
        } else {
          this.setState({ showRegionMessage: true });
        }
      }
    } catch (err) {
      console.log("Error:" + err);
    }
  };

  componentDidMount() {
    this.getAllCountries();

    if (this.state.userRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo,
        state: {
          countryId: this.state.editId,
          name: this.state.name,
          isAdminRole: this.state.isAdminRole
        }
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
            <tbody>
              <tr>
                <td><span className="pageTitle">Country Administration</span></td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}><Link to={`/countryNew/`}><button style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Add Country | <img src={plus}></img></button></Link></td>
              </tr>
            </tbody>
          </table>

          <div style={{ margin: '10px 0px 0px 0px' }}>
            <div style={{ display: this.state.errMsg_region, margin: '20px 0px 0px 0px' }}>
              <CountryComponent onCreate={() => this.getAllCountries()}
                handleDeleteCountry={this.handleDeleteCountry}
                handleEditCountry={this.handleEditCountry}
                tickets={data}
              />
            </div>
          </div>

          <div>
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
          </div>
        </div>

        <Modal
          show={this.state.showDeleteMessage}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Delete Country</span>
            </p>
            <p>
              <span>Are you sure you want to delete this country?</span>
            </p>
            <p>
              <button className="btn btn-secondary" onClick={this.deleteCountry} style={{ margin: '0px 10px 0px 0px' }} >
                Yes
              </button>
              <button className="btn btn-danger" onClick={this.handleClose} >
                No
              </button>
            </p>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showRegionMessage}
          onHide={this.handleRegionClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Cannot delete country</span>
            </p>
            <p>
              <span>There are Regions attached to this country!</span>
            </p>
            <p>
              <button className="btn btn-danger" onClick={this.handleRegionClose} >
                Close
              </button>
            </p>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default countries;