import React, { Component, useRef, useEffect, useState } from 'react';
import AWS from "aws-sdk";
import configData from "../config.json";
import { Redirect } from 'react-router';
import Cookies from "universal-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

class fileUpload extends Component {

  constructor(props, context) {
    super(props, context);

        this.handleFileChange = this.handleFileChange.bind(this);

        this.state = {
            file: "",
            redirect: false
        };
    }

  // Function to upload file to s3
  uploadFile = async () => {
    const S3_BUCKET = configData.api.s3Bucket;
    const REGION = configData.api.awsRegion;

    AWS.config.update({
      accessKeyId: configData.api.awsAccessKeyId,
      secretAccessKey: configData.api.secretAccessKey,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: this.state.file.name,
      Body: this.state.file,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // File successfully uploaded
      alert("File uploaded successfully.");
    });
  };

  // Function to handle file and store it to file state
  handleFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // Changing file state
    this.setState({file: file});
  };

    render() {
        const style = {
            control: (base, state) => ({
                ...base,
                borderTop: 'none',
                borderRight: 'none',
                borderLeft: 'none',
                borderBottom: '1px solid #707070',
                boxShadow: 'none',
                '&:hover': {
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                    borderBottom: '1px solid #707070',
                }
            })
        };
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.editId
                }
            }} />;
        }
        return (
            <div className="App">
            <div>

            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload pernod.csv file</Form.Label>
                <Form.Control type="file" onChange={this.handleFileChange} />
            </Form.Group>

            <button onClick={this.uploadFile}>Upload</button>

                {/* <input type="file" onChange={this.handleFileChange} /> */}
                {/* <button onClick={this.uploadFile}>Upload</button> */}
            </div>
            </div>
        );
    }
}

export default fileUpload;