import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise.png";
import logout from "../log-out white.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";


const config = require("../config.json");

class countryedit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: this.props.location.state.isAdminRole,
      redirect: false,
      redirectTo: "/",
      id: this.props.location.state.Id,
      name: this.props.location.state.name,
      errMsg_region: "none",
      errMsg_name: "none"
    };
  }

  onNameChange = event => this.setState({ name: event.target.value });

  onAddVisitorRoleChange = event => {
    this.setState({ role: event.value });
    this.setState({ errMsg_role: "none" });
    this.setState({ errMsg_region: "none" });
    if (event.value === "Building Admin and Reception User") {
      this.setState({ regionDisabled: false });
    } else {
      this.setState({ regionDisabled: true });
    }
  }

  // onAddVisitorRegionChange = event => {
  //   this.setState({ name: event.value });
  //   this.setState({ errMsg_region: "none" });
  // }

  updateInputValue(evt) {
    this.setState({ inputValue: evt.target.value });
  }

  onSelectAdminUsers() {
    this.setState({ redirectTo: "/adminUsers" });
    this.setState({ redirect: true });
  }

  handleUpdateCountry = e => {
    e.preventDefault();

    let formValid = true;

    if (this.state.name === '') {
      this.setState({ errMsg_name: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_name: "none" });
    }

    if (formValid === true) {
      console.log('form is valid: submit');
      this.updateCountry();
    }
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g,"_");
    return string;
  }

  updateCountry = async () => {
    try {
      const params = {
        "Id": this.removeWhiteSpace( this.state.id ),
        "countryname": this.state.name
      };

      // console.log(params);

      await axios.patch(`${config.api.invokeUrl}/country/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ redirectTo: "/countryAdmin" });
      this.setState({ redirect: true });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px'  }}  />Country Administration</button>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px'  }}  />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Edit Country</h5>
            <br></br>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>

              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={this.handleUpdateCountry}
                noValidate>

                <div className="container">
                  <div className="form-row">
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Country Name"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.name}
                    />
                  </div>

                  <div className="form-row">
                    <span style={{ margin: '0px 0px 0px 40px', display: this.state.errMsg_name, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/countryAdmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>
                </div>

              </FormWithConstraints>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default countryedit;