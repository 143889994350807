import React, { useState, Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import profileICO from "../user_admin.png";
import mobileICO from "../user_calls-contacts.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import emailICO from "../user_mail-new.png";
import Button from "react-bootstrap/Button";
import searchWhite from "../searchWhite.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const config = require("../config.json");
const cookies = new Cookies();

const options = [
  { value: "Building Admin and Reception User", label: "Building Admin and Reception User" },
  { value: "Security User", label: "Security User" },
  { value: "Administrator", label: "Administrator" },
  { value: "Super User", label: "Super User" },
];

let countryId = "";
let regionId = "";
let sCountry = "";

class adminuseredit extends Component {

  constructor(props, context) {
    super(props, context);

    this.onCountryChange = this.onCountryChange.bind(this);
    this.onRegionChange = this.onRegionChange.bind(this);

    this.state = {
      isAdminRole: this.props.location.state.isAdminRole,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/adminUsers",
      showVisitorDontExist: "none",
      showVisitorExist: "none",
      errMsg_role: "none",
      errMsg_notadmin: "none",
      errMsg_country: "none",
      errMsg_region: "none",
      countryDisabled: true,
      regionDisabled: true,
      countryOptions: [],
      regionOptions: [],
      id: "",
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      country: "",
      region: "",
      buildings: "",
      role: "",
      username: "",
      password: "",
      prevpass1: "",
      prevpass2: "",
      prevpass3: ""
    };

  }

  onAddVisitorRoleChange = event => {
    this.setState({ role: event.value });
    this.setState({ errMsg_role: "none" });
    if (event.value === "Building Admin and Reception User") {
      if (this.state.userRole === "Administrator") {
        this.getAllCountries();
        this.setState({ countryDisabled: false });
        this.setState({ regionDisabled: false });
        this.getAllRegions();
      } else {
        this.setDefaultCountry();
        this.setState({ regionDisabled: false });
        // this.getAllCountries();
        // this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) })
        // sCountry = this.state.countryId;
        this.setState({ region: "" });
        this.getAllRegions();
      }
    } else if (this.state.userRole === "Administrator") {
      this.getAllCountries();
      this.setState({ countryDisabled: false });
      this.setState({ regionDisabled: true });
    } else if (this.state.userRole === "Super User") {
      this.setDefaultCountry();
      this.setState({ countryDisabled: true });
    } else {
      this.setState({ countryDisabled: true });
      this.setState({ regionDisabled: true });
      // this.setState({ country: "" });
      // this.setState({ region: "" });
    }
  }

  onCountryChange = event => {
    this.setState({ country: event.value });
    countryId = event.value;

    // console.log(countryId);
    this.setState({ region: "" });
    this.getAllRegions();
  }

  onRegionChange = event => {
    this.setState({ region: event.value });
    regionId = event.value;
    this.setState({ errMsg_region: "none" });
    // console.log(regionId);
  }

  setDefaultCountry() {
    sCountry = this.state.adminCountry;
    // console.log(sCountry);

    let obj = { value: this.removeWhiteSpace(sCountry), label: sCountry };
    this.setState({ countryId: obj }, () => {
      // Do an API call with this.state.searchTerm

    });
  }

  // onCountryChange = event => {
  //   // this.setState({ country: this.removeUnderscore(event.value) });
  //   this.setState({ country: event.value });

  //   this.setState({ regionOptions: [] });

  //   // this.setState({ countryName: this.removeUnderscore( event.value )});
  //   // countryId = event.value;
  //   // countryName = this.removeUnderscore( event.value );
  //   // this.setState({ region: "" });
  //   // this.setState({ regionName: "" });

  //   this.forceUpdate();
  //   this.getAllRegions(this.state.country);


  //   // this.props.onCreate();
  // }

  // onRegionChange = event => {
  //   // this.setState({ region: this.removeUnderscore(event.value) });
  //   this.setState({ region: event.value });

  //   // this.forceUpdate();

  //   // this.setState({ regionId: this.removeUnderscore( event.value )});    
  //   // regionId = event.value;
  //   // regionName = this.removeUnderscore( event.value );
  //   this.setState({ errMsg_region: "none" });
  // }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  componentDidMount() {

    this.fetchAdmins();
    // this.getAllCountries();
    // console.log(this.state.userRole);
    // if (this.state.userRole !== "Administrator") {
    //   // this.getAllCountries();
    //   this.setState({ country: this.removeWhiteSpace(this.state.adminCountry) })
    //   countryId = this.state.country;
    //   this.setState({ countryDisabled: true });
    //   this.getAllRegions();

    // }
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      const countrydata = response.data;
      const options = countrydata.map(d => ({
        "value": d.Id,
        "label": d.countryname
      }))

      // console.log(options);
      this.setState({ countryOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  getAllRegions = async () => {

    const param = this.removeWhiteSpace(countryId);
    // console.log(param);

    if (param !== '') {
      try {
        const response = await axios.get(`${config.api.invokeUrl}/region/` + param, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        // console.log(response.data);
        const regiondata = response.data;
        const options = regiondata.map(d => ({
          "value": d.Id,
          "label": d.regionname
        }))

        this.setState({ regionOptions: options })

        // this.setState({ errMsg_region: "block" });
      } catch (err) {
        console.log("error");
      }
    }
  }

  fetchAdmins = async () => {
    try {
      const params = {
        "id": this.props.location.state.id.toLowerCase()
      };

      const res = await axios.post(`${config.api.invokeUrl}/adminuserupdate/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      // console.log(res.data);

      this.setState({ admins: res.data }, function () {
        // console.log(this.state.visitors);
        // console.log(this.state.visitors.length);
      });

      //console.log(res);
      if (this.state.admins.length == 0) {
        // this.setState({showLogonError: "block"});               
      } else {
        this.setState({ id: res.data[0].id });
        this.setState({ firstname: res.data[0].name });
        this.setState({ lastname: res.data[0].lastname });
        this.setState({ mobile: res.data[0].mobile });
        this.setState({ email: res.data[0].email });
        this.setState({ country: res.data[0].country });
        this.setState({ region: res.data[0].buildingsregion });
        this.setState({ role: res.data[0].userrole });
        this.setState({ buildings: res.data[0].buildings });
        this.setState({ username: res.data[0].username });
        this.setState({ password: res.data[0].password });
        this.setState({ prevpass1: res.data[0].prevpass1 });
        this.setState({ prevpass2: res.data[0].prevpass2 });
        this.setState({ prevpass3: res.data[0].prevpass3 });

        if (res.data[0].userrole === "Building Admin and Reception User") {
          if (this.state.userRole === "Administrator") {
            this.setState({ countryDisabled: false });
            this.setState({ regionDisabled: false });
          } else {
            this.setState({ regionDisabled: false });
            // this.getAllCountries();
            this.setState({ country: this.removeWhiteSpace(this.state.adminCountry) })
            countryId = this.state.country;
            // this.setState({ region: "" });
            this.getAllRegions();
          }
        } else if (this.state.userRole === "Administrator") {
          this.getAllCountries();
          this.setState({ countryDisabled: false });
          this.setState({ regionDisabled: true });
        } else {
          this.setState({ countryDisabled: true });
          this.setState({ regionDisabled: true });
        }
      }

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  updateAdmin = async () => {
    // Get Buildings for the selected region
    let buildings = " ";

    if (this.state.role === "Building Admin and Reception User") {
      try {
        const responseBuildings = await axios.get(`${config.api.invokeUrl}/buildings/` + this.state.region, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        // console.log(responseBuildings);
        let buildingsData = responseBuildings.data;

        // buildingsData.map((bld, index) => buildings = buildings + ' ' + bld.buildingname + ','); 

        buildingsData.map((item, i) => {
          if (item.buildingname != 'undefined') {
            buildings = buildings + ' ' + item.buildingname + ','
          }
        });

        buildings = buildings.replaceAll(' undefined,', '');
        buildings = buildings.replaceAll('undefined ,', '');
        buildings = buildings.replaceAll('undefined,', '');

        // console.log(buildings);
      } catch (err) {
        console.log("error");
      }
    } else {
      this.setState({ region: "" })
    }

    // add call to AWS API Gateway add visitor endpoint here
    try {
      const id = this.state.id;
      // console.log("the id: " + id);

      const params = {
        "id": this.state.id,
        "username": this.state.username,
        "password": this.state.password,
        "prevpass1": this.state.prevpass1,
        "prevpass2": this.state.prevpass2,
        "prevpass3": this.state.prevpass3,
        "userrole": this.state.role,
        "buildingsregion": this.state.region,
        "buildings": buildings,
        // "country": this.removeWhiteSpace(this.state.country)
        "country": this.state.country
      };

      await axios.patch(`${config.api.invokeUrl}/adminusers/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if (this.state.role === "Building Admin and Reception User") {
        this.sendEmailMessage(this.state.password);
      }

      toast.success('User successfully updated');

      //alert("Password was successfully reset.");
      this.setState({ redirect: true });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleUpdateUser = e => {
    e.preventDefault();
    let formValid = true;

    if ((this.state.role === 'Administrator') && (this.state.userRole !== 'Administrator')) {
      this.setState({ errMsg_notadmin: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_notadmin: "none" });
    }

    if (this.state.role === '') {
      this.setState({ errMsg_role: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_role: "none" });
    }

    if (this.state.countryDisabled === false) {
      if (this.state.country === '') {
        this.setState({ errMsg_country: "block" });
        formValid = false;
      } else {
        this.setState({ errMsg_country: "none" });
      }
    }

    if (this.state.regionDisabled === false) {
      if (this.state.region === '') {
        this.setState({ errMsg_region: "block" });
        formValid = false;
      } else {
        this.setState({ errMsg_region: "none" });
      }
    }

    if (formValid) {
      console.log('form is valid: submit');
      this.updateAdmin();
    }
  }

  sendEmailMessage = (newPassword) => {
    // console.log('sendEmailMessage');
    const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    const emailBody = "<p>Dear " + this.state.firstname + " " + this.state.lastname + "<p /><span>Please be advised that you have been assigned a building admin role for the Reception Interface.</span><br/><p><span>Your username is: </span><b>" + this.state.email.toLowerCase() + "</b><br/><span>Your password is: </span><b>" + newPassword + "</b><br /><p><span>Kind Regards</span><br/><span>National Facilities</span></p>";

    var data = JSON.stringify(
      {
        "apiKey": "ea46673c4c890903a6c7c328568ca326",
        "message": emailBody,
        "html": "True",
        "to": [this.state.email.toLowerCase()],
        "subject": "Building Admin",
        "from": "visitors@vodacom.co.za"
      });

    var config = {
      method: 'post',
      url: 'https://engageplatform.vodacom.co.za/web/email',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (

      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Edit User</h5>
            <br></br>
            <FormWithConstraints
              ref={form => this.form = form}
              onSubmit={this.handleUpdateUser}
              noValidate>
              <div className="container">
                <div className="form-row">
                  <img src={profileICO} style={{ margin: '10px 5px 0px 0px' }} />
                  <input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="First Name"
                    className="form-control+matching-text-style"
                    style={{ width: '350px', color: "#4A4D4E" }}
                    value={this.state.firstname}
                    readOnly
                  />
                </div>

                <div className="form-row">
                  <img src={profileICO} style={{ margin: '10px 5px 0px 0px' }} />
                  <input
                    name="surname"
                    id="surname"
                    type="text"
                    placeholder="Last Name"
                    className="form-control+matching-text-style"
                    required
                    style={{ width: '350px', color: "#4A4D4E" }}
                    value={this.state.lastname}
                    readOnly
                  />
                </div>

                <div className="form-row">
                  <img src={emailICO} style={{ margin: '10px 5px 0px 0px' }} />
                  <input
                    name="Email"
                    id="Email"
                    type="email"
                    placeholder="Email Address"
                    className="form-control+matching-text-style"
                    value={this.state.email}
                    style={{ width: '350px', color: "#4A4D4E" }}
                    readOnly
                  />
                </div>

                <div className="form-row">
                  <img src={mobileICO} style={{ margin: '10px 5px 0px 0px' }} />
                  <input
                    name="Mobile"
                    id="Mobile"
                    type="text"
                    placeholder="Contact Number"
                    className="form-control+matching-text-style"
                    value={this.state.mobile}
                    style={{ width: '350px', color: "#4A4D4E" }}
                    readOnly
                  />
                </div>

                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>

                  <img src={network} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />

                  <div style={{ width: "350px" }}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      options={options}
                      placeholder="Select role "
                      onChange={this.onAddVisitorRoleChange}
                      value={{ label: this.state.role, value: this.state.role }}
                      required
                      styles={style}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_role, color: 'red' }}>Please complete.</span>
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_notadmin, color: 'red' }}>You do not have enough privilege to choose this option.</span>
                  </div>
                </div>

                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                  <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                  <div style={{ width: "350px" }}>
                    <Select
                      name="country"
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={this.state.countryDisabled}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      placeholder="Select country "
                      onChange={this.onCountryChange}
                      required
                      styles={style}
                      options={this.state.countryOptions}
                      value={{ value: this.state.country, label: this.removeUnderscore(this.state.country) }}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    >
                    </Select>
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                  </div>
                </div>

                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                  <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                  <div style={{ width: "350px" }}>
                    <Select
                      name="region"
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={this.state.regionDisabled}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      placeholder="Select region "
                      onChange={this.onRegionChange}
                      required
                      styles={style}
                      options={this.state.regionOptions}
                      value={{ value: this.state.region, label: this.removeUnderscore(this.state.region) }}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    >
                    </Select>
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_region, color: 'red' }}>Please complete.</span>
                  </div>
                </div>

                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                  <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                  <Link to={`/adminUsers/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                </div>

                <div>
                  <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                  />
                </div>
              </div>
            </FormWithConstraints>
          </div>
        </div>
      </div>
    );
  }
}

export default adminuseredit;