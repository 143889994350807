import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprisew from "../enterprise white.png";
import enterprise from "../enterprise.png";
import logout from "../log-out white.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require("../config.json");
const cookies = new Cookies();

let sCountry = "";
let sRegion = "";

class buildingnew extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/",
      countryId: "",
      regionId: "",
      buildingName: "",
      errMsg_country: "none",
      errMsg_region: "none",
      errMsg_name: "none",
      countryOptions: [],
      regionOptions: []
    };
  }

  onNameChange = event => this.setState({ buildingName: event.target.value });

  onCountryChange = event => {
    sCountry = event.value;
    this.setState({ errMsg_country: "none" });
    let obj = {value: event.value, label: event.label};
    this.setState({ countryId: obj },() => {
      
    });
    // console.log(obj);
    // console.log(sCountry);
    this.getAllRegions();
  }

  onRegionChange = event => {
    sRegion = event.value;
    this.setState({ errMsg_region: "none" });
    let obj = {value: event.value, label: event.label};
    this.setState({ regionId: obj },() => {
      
    });    
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g,"_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g," ");
    return string;
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      const countrydata = response.data;

      const options = countrydata.map(d => ({
        "value": d.Id,
        "label": d.countryname
      }))

      this.setState({ countryOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  getAllRegions = async () => {
    try {
      console.log(sCountry);
      const response = await axios.get(`${config.api.invokeUrl}/region/?id=` + sCountry, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      const regiondata = response.data;

      const options = regiondata.map(d => ({
        "value": d.Id,
        "label": d.regionname
      }))

      this.setState({ regionOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  }

  componentDidMount() {
    this.getAllCountries()
    
    // console.log(this.state.userRole);
    if (this.state.userRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }

    if (this.state.userRole !== "Administrator") {
      this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });
      sCountry = this.removeWhiteSpace(this.state.adminCountry);
      this.setState({ countryDisabled: true });
      // console.log(sCountry);
      this.getAllRegions();
    }
  }

  handleAddBuilding = async (id, event) => {
    event.preventDefault();
    let formValid = true;

    if (this.state.countryId === '') {
      this.setState({ errMsg_country: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_country: "none" });
    }

    if (this.state.regionId === '') {
      this.setState({ errMsg_region: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_region: "none" });
    }

    if (this.state.buildingName === '') {
      this.setState({ errMsg_name: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_name: "none" });
    }

    if (formValid === true) {
      // add call to AWS API Gateway add product endpoint here 
      const guid = uuidv4();

      try {
        const params = {
          "id": guid,
          "name": this.state.buildingName,
          "region": sRegion
        };

        console.log(params);

        await axios.post(`${config.api.invokeUrl}/buildings/{id}`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        })
        // .then(() => {
          // this.props.onCreate();
        this.setState({ redirectTo: "/buildingAdmin" });
        this.setState({ redirect: true });
        // })
        // .catch(err => console.log(err));

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton" ><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={enterprisew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Add New Building</h5>
            <br></br>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>

              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={event => this.handleAddBuilding(this.state.buildingName, event)}
                noValidate>
                <div className="container">

                {this.state.isAdminRole && (
                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.countryDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select country "
                        onChange={this.onCountryChange}
                        required
                        styles={style}
                        options={this.state.countryOptions}
                        // value={{ value: this.state.countryId, label: this.removeUnderscore(this.state.countryId) }}
                        value={this.state.countryId}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>
                )}

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.regionDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select region "
                        onChange={this.onRegionChange}
                        required
                        styles={style}
                        options={this.state.regionOptions}
                        // value={{ value: this.state.regionId, label: this.removeUnderscore(this.state.regionId) }}
                        value={this.state.regionId}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_region, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row">
                    <p></p>
                  </div>

                  <div className="form-row">

                    <img src={enterprise} style={{ margin: '0px 5px 0px 0px' }} />

                    <input
                      name="buildingName"
                      id="buildingName"
                      type="text"
                      placeholder="Building Name"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                    />
                  </div>
                  <div className="form-row">
                    <span style={{ margin: '0px 0px 0px 40px', display: this.state.errMsg_name, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>

                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/buildingAdmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>
                </div>
              </FormWithConstraints>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default buildingnew;