import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import { Link } from "react-router-dom";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import Select from "react-select";
import { Redirect } from 'react-router';
import plus from "../plus.png";
import RejectionsComponent from "./rejectionsComponent";
import Modal from "react-bootstrap/Modal";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require('../config.json');
const cookies = new Cookies();
const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let data = [];
let sCountry = "";

class rejections extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/",
      errMsg_country: "none",
      errMsg_region: "none",
      showDeleteMessage: false,
      region: "",
      countryId: "",
      deleteId: "",
      editId: "",
      setTickets: [],
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getUTCFullYear()
    };
  }

  onCountryChange = event => {
    // this.setState({ countryId: event.value });
    this.setState({ errMsg_country: "none" });

    let obj = { value: event.value, label: event.label };
    this.setState({ countryId: obj });   //  convert to obj
    sCountry = event.value;
    // console.log(sCountry);
    // console.log(this.state.countryId);
    this.getAllRejections();
  }

  componentDidMount() {
    //this.getAllRejections();
    this.getAllCountries();

    if (this.state.userRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }

    if (this.state.userRole !== "Administrator") {
      this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });
      sCountry = this.removeWhiteSpace(this.state.adminCountry);
      this.setState({ countryDisabled: true });
      // console.log(sCountry);
      this.getAllRejections();
    }
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      const regiondata = response.data;

      const options = regiondata.map(d => ({
        "value": d.Id,
        "label": d.countryname
      }))

      // console.log(options);

      this.setState({ selectOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  getAllRejections = async () => {
    const params = {
      "ym": this.state.currentYear.toString() + (this.state.currentMonth + 1).toString(),
      "country": sCountry
    };
    try {
      const response = await axios.post(`${config.api.invokeUrl}/rejectpolicy`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      // console.log(response.data);        
      this.setState({ setTickets: response.data });
      data = response.data;
      //  console.log(data);          
    } catch (err) {
      console.log("error");
    }
  };

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo,
        state: {
          id: this.state.editId,
          region: this.state.region,
          name: this.state.name
        }
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>

        <div className="main">
          <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
            <tbody>
              <tr>
                <td><span className="pageTitle">Privacy Rejection Report | </span><span> 01 {month_names_short[this.state.currentMonth]} to current</span></td>
                <td style={{ textAlign: "right" }}></td>
              </tr>
            </tbody>
          </table>

          <div style={{ margin: '10px 0px 0px 0px' }}>
            <div className="container">
              {this.state.isAdminRole && (
                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                  <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                  <div style={{ width: "350px" }}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={this.state.countryDisabled}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      placeholder="Select country "
                      onChange={this.onCountryChange}
                      required
                      styles={style}
                      options={this.state.selectOptions}
                      // value={{ value: this.state.countryId, label: this.removeUnderscore(this.state.countryId) }}
                      value={this.state.countryId}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    >
                    </Select>
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                  </div>
                </div>
              )}

              <div style={{ margin: '10px 0px 0px 0px' }}>
                <div style={{ margin: '20px 0px 0px 0px' }}>
                  <RejectionsComponent tickets={this.state.setTickets} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default rejections;