import React, { Component, Fragment }  from 'react';
import "./contact.css"

export default class Contact extends Component {
  
  handleEditSave = event => {
    event.preventDefault();
    this.props.handleSelectContact(this.props.name, this.props.surname, this.props.mail, this.props.mobile, this.props.photo);
  }

    render() {
      return (
        <div className="tile is-child box notification is-success">
          
           <div>
            <div id="wrapper" onClick={ this.handleEditSave } style={{ cursor: "pointer"  }} >
                    <div id="first"><img className="img-circular" src={`data:image/jpeg;base64,${this.props.photo}`} /></div>
                    <div id="second" >
                        <div><span className="spanFont" >{ this.props.name }</span>{' '}<span className="spanFont" >{ this.props.surname }</span></div>
                        <div><span className="spanFont">{ this.props.mail }</span></div>
                        <div><span className="spanFont">{ this.props.mobile }</span></div>
                    </div>
                </div> 
            </div>
        </div>
      )
    }
  }
  