import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

export default class SurveyComponent extends Component {

  handleEditSave = event => {
    event.preventDefault();
    this.props.handleSelectContact(this.props.mail);
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  render() {
    return (
      <div className="container" style={{ height: "700px", overflowY: "scroll" }}>
        {this.props.tickets === undefined ? (
          "Loading..."
        ) : (
          <table className="table" style={{ margin: '0px 0px 50px 0px' }}>
            <thead style={{ backgroundColor: '#4A4D4E', color: 'white' }}>
              <tr>
                <th scope="col">Survey Title</th>
                <th scope="col">Question</th>
                <th scope="col">Date Created</th>
                <th scope="col">Country</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {this.props.tickets.map(ticket => (
                <tr key={ticket.id}>
                  <td>{ticket.title}</td>
                  <td>{ticket.question}</td>
                  <td>{ticket.datecreated}</td>
                  <td>{this.removeUnderscore(ticket.country)}</td>
                  <td>{ticket.statussurvey}</td>
                  <td style={{ width: '220px' }}>
                    <button onClick={event => this.props.handleEditSurvey(ticket.id, ticket.title, ticket.question, ticket.country, ticket.statussurvey, event)} style={{ margin: '0px 0px 0px 0px' }} className="btn btn-secondary">Edit</button>
                    <button onClick={event => this.props.handleDeleteSurvey(ticket.surveyId, event)} style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

    )
  }
}
