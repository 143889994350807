import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprisew from "../enterprise white.png";
import enterprise from "../enterprise.png";
import logout from "../log-out white.png";
import profileICO from "../user_admin.png";
import mobileICO from "../user_calls-contacts.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import profile from "../profile2.png";
import region from "../location_white.png";
import emailICO from "../user_mail-new.png";
import Button from "react-bootstrap/Button";
import searchWhite from "../searchWhite.png";
import Contact from "./contact";
import axios from "axios";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require("../config.json");
const cookies = new Cookies();

let sCountry = "";

class employeenew extends Component {
  constructor(props, context) {
    super(props, context);

    //this.onSelectEmployee = this.onSelectEmployee.bind(this);
    // this.searchContactsClick = this.searchContactsClick.bind(this);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/employee",
      countryDisabled: true,
      regionDisabled: true,
      errMsg_role: "none",
      errMsg_notadmin: "none",
      errMsg_name: "none",
      errMsg_lastname: "none",
      errMsg_email: "none",
      errMsg_mobile: "none",
      errMsg_title: "none",
      errMsg_department: "none",
      errMsg_region: "none",
      errMsg_country: "none",
      inputValue: "",
      contactName: "",
      searchState: "",
      // showFields: 'none',
      contactsShow: 'none',
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      title: "",
      department: "",
      photo: "",
      role: "",
      countryId: [],
      region: "",
      countryOptions: [],
      regionOptions: []
    };
  }

  employeeNameChange = event => this.setState({ firstname: event.target.value });
  employeeLastnameChange = event => this.setState({ lastname: event.target.value });
  // onAddEmployeeIdChange = event => this.setState({ id: event.target.value });
  employeeEmailChange = event => this.setState({ email: event.target.value });
  employeeMobileChange = event => this.setState({ mobile: event.target.value });
  employeeTitleChange = event => this.setState({ title: event.target.value });
  employeeDepartmentChange = event => this.setState({ department: event.target.value });

  onCountryChange = event => {
    sCountry = event.value;
    let obj = { value: event.value, label: event.label };
    this.setState({ countryId: obj }, () => {
    });

    // console.log(obj);
    // console.log(this.state.countryId);

    this.getAllRegions();
  }

  onRegionChange = event => {
    this.setState({ region: event.value });
    this.setState({ errMsg_region: "none" });
    // console.log(this.state.region);
  }

  setDefaultCountry() {
    sCountry = this.state.adminCountry;
    // console.log(sCountry);

    let obj = { value: this.removeWhiteSpace(sCountry), label: sCountry };
    this.setState({ countryId: obj }, () => {
      // Do an API call with this.state.searchTerm

    });
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value,
    });
  }

  handleaddEmployee = (id, event) => {
    event.preventDefault();

    let formValid = true;

    if (this.state.name === '') {
      this.setState({ errMsg_name: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_name: "none" });
    }

    if (this.state.lastname === '') {
      this.setState({ errMsg_lastname: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_lastname: "none" });
    }

    if (this.state.email === '') {
      this.setState({ errMsg_email: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_email: "none" });
    }

    // if (this.state.regionDisabled === false) {
    //   if (this.state.region === '') {
    //     this.setState({ errMsg_region: "block" });
    //     formValid = false;
    //   } else {
    //     this.setState({ errMsg_region: "none" });
    //   }
    // }

    // if (this.state.countryDisabled === false) {
    //   if (this.state.countryId === '') {
    //     this.setState({ errMsg_country: "block" });
    //     formValid = false;
    //   } else {
    //     this.setState({ errMsg_country: "none" });
    //   }
    // }

    if (formValid === true) {
      console.log('form is valid: submit');
      this.addEmployee();
    }
  }

  addEmployee = async () => {

    // if ((this.state.role === 'Administrator') && (this.state.userRole !== 'Administrator')) {
    //   this.setState({ errMsg_notadmin: "block" });
    //   formValid = false;
    // } else {
    //   this.setState({ errMsg_notadmin: "none" });
    // }
    const guid = uuidv4();
    let fullName = this.state.firstname +" "+this.state.lastname;

      try {
        const params = {
          "id": guid,
          "name": this.state.firstname,
          "lastname": this.state.lastname,
          "email": this.state.email.toLowerCase(),
          "mobile": this.state.mobile,
          "title": this.state.title,
          "department": this.state.department,
          "preferredname": fullName,
          "searchablename": fullName.toLowerCase(),
          "region": this.state.region,
          "country": this.removeWhiteSpace(sCountry)
        };

        // console.log(params);
        await axios.post(`${config.api.invokeUrl}/employee/{id}`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });

        this.setState({ redirectTo: "/employee" });
        this.setState({ redirect: true });

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    
  }

  componentDidMount() {
    this.getAllCountries();

    // console.log(this.state.userRole);
    if (this.state.userRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }

    // if (this.state.userRole !== "Administrator") {   
    //   sCountry = this.removeWhiteSpace(this.state.adminCountry);
    //   this.setState({ countryDisabled: true });
    //   this.setState({ countryId: sCountry });
    //   this.getAllRegions();
    // }


    // console.log(this.state.userRole);
    // if (this.state.userRole === "Administrator") {
    //   this.setState({ isAdminRole: true });
    // } else if (this.state.userRole !== "Administrator") {   
    //   // this.getAllCountries();
    //   this.setState({ country: this.removeWhiteSpace(this.state.adminCountry) });
    //   sCountry = this.state.country;
    //   this.setState({ region: "" });
    //   this.getAllRegions();
    // }
    // isOptionDisabled(option: OptionType, selectValue: OptionsType): boolean { 
    //   debugger; 
    //   return typeof this.props.isOptionDisabled === 'function' ? this.props.isOptionDisabled(option, selectValue) : (typeof this.option.disabled !== 'undefined' ? this.option.disabled : false );

  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country/`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      //console.log(response.data);
      const countrydata = response.data;

      const options = countrydata.map(d => ({
        "value": d.id,
        "label": d.countryname
      }))

      // console.log(options);

      this.setState({ countryDisabled: false })
      this.setState({ countryOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error getting all countries");
    }
  };

  getAllRegions = async () => {
    try {
      const param = this.removeWhiteSpace(sCountry);
      //console.log(param);

      if (param !== '') {
        const response = await axios.get(`${config.api.invokeUrl}/region/` + param, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        // console.log(response.data);
        const regiondata = response.data;

        const options = regiondata.map(d => ({
          "value": d.id,
          "label": d.regionname
        }))

        // console.log(options);
        this.setState({ regionDisabled: false })
        this.setState({ regionOptions: options })
      }


      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error getting region for :" + sCountry);
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprisew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '20px 0px 0px 20px' }}>
            <h5 className="pageTitle">Add Employee</h5>
            <br></br>

            <div style={{ margin: '10px 0px 0px 0px' }}>
              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={event => this.handleaddEmployee(this.state.email, event)}
                noValidate>
                <div className="container">
                  <div className="form-row">
                    <img src={profileICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder="First Name"
                      className="form-control+matching-text-style"
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.firstname}
                      onChange={this.employeeNameChange}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_name, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row">
                    <img src={profileICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="surname"
                      id="surname"
                      type="text"
                      placeholder="Last Name"
                      className="form-control+matching-text-style"
                      required
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.lastname}
                      onChange={this.employeeLastnameChange}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_lastname, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row">
                    <img src={emailICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="Email"
                      id="Email"
                      type="email"
                      placeholder="Email Address"
                      className="form-control+matching-text-style"
                      value={this.state.email}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      onChange={this.employeeEmailChange}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_email, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row">
                    <img src={mobileICO} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="Mobile"
                      id="Mobile"
                      type="text"
                      placeholder="Contact Number"
                      className="form-control+matching-text-style"
                      value={this.state.mobile}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      onChange={this.employeeMobileChange}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_mobile, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row">
                    <img src={profile} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="Title"
                      id="Title"
                      type="text"
                      placeholder="Title"
                      className="form-control+matching-text-style"
                      value={this.state.title}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      onChange={this.employeeTitleChange}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_title, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row">
                    <img src={enterprise} style={{ margin: '10px 5px 0px 0px' }} />
                    <input
                      name="Department"
                      id="Department"
                      type="text"
                      placeholder="Department"
                      className="form-control+matching-text-style"
                      value={this.state.department}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      onChange={this.employeeDepartmentChange}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_department, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.countryDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        placeholder="Select country "
                        onChange={this.onCountryChange}
                        required
                        styles={style}
                        options={this.state.countryOptions}
                        value={this.state.countryId}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.regionDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        placeholder="Select region "
                        onChange={this.onRegionChange}
                        required
                        styles={style}
                        options={this.state.regionOptions}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_region, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/employee/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>

                </div>
                <div className="container">

                </div>
              </FormWithConstraints>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default employeenew;