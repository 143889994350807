import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import admin from "../admin.png";
import security from "../security.png";
import headerLogo from "../Pernod_Ricard-logo.jpg"
import "./logon.css";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import axios from "axios";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";

const config = require("../config.json");
const cookies = new Cookies();

class logon extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            redirect: false,
            showVisitorDontExist: "none",
            showVisitorExist: "none",
            showLogonError: "none",
            admins: [],
            username: "",
            password: "",
            authenticated: false,
            token: "",
            role: "",
            showUnauthorisedError: false
        };
    }

    handleChange = e => {
        this.setState({ username: e.target.value })
        this.form.validateFields(e.target);
    }

    handleChangePassword = e => {
        this.setState({ password: e.target.value })
        this.form.validateFields(e.target);
    }

    handleClose() {
        this.setState({ showDeleteMessage: false });
        this.setState({ showUnauthorisedError: false });
    }

    contactSubmit = e => {
        e.preventDefault();
        this.form.validateFields();
        if (!this.form.isValid()) {
            console.log('form is invalid: do not submit');
        } else {
            console.log('form is valid: submit');
            this.fetchContactLogin();
            //this.setState({redirect: true})     
        }
    }

    fetchContactLogin = async () => {
        // console.log(this.state.username);
        // console.log(this.state.password);

        try {
            const params = {
                "id": this.state.username.toLowerCase(),
                "password": this.state.password
            };
            //const res = await axios.post(`${config.api.adminuserauth}/adminuser/`, params, {
            const res = await axios.post(`${config.api.invokeUrl}/adminuserauth/`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            this.setState({ admins: res.data }, function () {
                // console.log(this.state.visitors);
            });

            if (this.state.admins.length == 0) {
                this.setState({ showLogonError: "block" });
              } else {
        
                if (res.status == "208") {
        
                  // Frans - Added local storage for session token
                  localStorage.setItem('usersession', res.data.usersession);
                  localStorage.setItem('userdisplayname', res.data.display_name);
                  localStorage.setItem('email', res.data.email);
                  localStorage.setItem('username', res.data.username);
        
                  this.setState({showNotAllowed: "none"});
                  this.setState({showLogonError: "none"});
                  this.setState({redirect: true});
        
                  try{
                    const param = {
                      //id: res.data.email.toLowerCase()
                      id: localStorage.getItem('username'),
                      usid: localStorage.getItem('usersession')
                    };
                    //const resAdmin = await axios.post(`${config.api.invokeUrl}/adminusers/` , param, {            
                    const resAdmin = await axios.post(`${config.api.invokeUrl}/adminusers/`, param, {
                        "headers": {
                            "x-api-key": `${config.api.xapikey}`
                        }
                    });

                    console.log(resAdmin.data[0]);
                            
                    if (resAdmin.data.length === 0) {
                        this.setState({ showUnauthorisedError: true });
                    } else {
                        this.setState({ role: resAdmin.data[0].userrole })
        
                        const cookies = new Cookies();
                        
                        cookies.set("DVAdminRole", resAdmin.data[0].userrole, { path: "/" });
                        cookies.set("DVAdminName", resAdmin.data[0].name, { path: "/" });
                        cookies.set("DVAdminMail", resAdmin.data[0].email, { path: "/" });
                        cookies.set("DVAdminCountry", resAdmin.data[0].country, { path: "/" });
                        cookies.set("DVAdminMobile", resAdmin.data[0].mobile, { path: "/" });
                        // if (resAdmin.data.photo.length > 4096) {
                        //     cookies.set("DVAdminPhoto", '', { path: "/" });
                        // } else {
                        //     cookies.set("DVAdminPhoto", resAdmin.data.photo, { path: "/" });
                        // }
                        this.setState({ redirect: true });
                    }
        
                  } catch (err) {
                    console.log(`An error has occurred: ${err}`);
                  }
                  // //alert(res.data[0].userrole);
                  
        
                } else {
                  this.setState({ showLogonError: "block" });
                }
              }
            
        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    componentDidMount() {
        cookies.set("DVAdminRole", '', { path: "/" });
        cookies.set("DVAdminName", '', { path: "/" });
        cookies.set("DVAdminMail", '', { path: "/" });
        cookies.set("DVAdminMobile", '', { path: "/" });
        cookies.set("DVAdminCountry", '', { path: "/" });
        cookies.set("DVAdminPhoto", '', { path: "/" });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                // pathname: "/otpfields",
                pathname: "/adminUsers",
                state: {
                    mobile: this.state.mobile
                }
            }} />;
        }
        return (
            <div style={{ textAlign: "center" }}>
                <FormWithConstraints
                    ref={form => this.form = form}
                    onSubmit={this.contactSubmit}
                    noValidate>
                    <div>
                        <p>
                            <img src={headerLogo} alt="Pernod Ricard" style={{ margin: '40px 0px 0px 0px', width: '300px' }}></img>
                            <h3 style={{ margin: '90px 0px 0px 0px' }} className="pageTitle">Welcome to</h3>
                            <h3 style={{ margin: '0px 0px 0px 0px' }} className="pageTitle">Visitor Management System Dashboard</h3>
                        </p>
                        <br></br>
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col xs={6}>
                                    <div className="form-group">
                                        <img src={admin} style={{ width: '20px', padding: '0px 0px 0px 0px', margin: '0px 3px 0px 0px' }} />

                                        <input
                                            name="username"
                                            id="username"
                                            type="text"
                                            placeholder="Email address"
                                            className="form-control+matching-text-style"
                                            required onChange={this.handleChange}
                                            style={{ width: '400px' }}
                                        />
                                        <FieldFeedbacks for="username">
                                            <FieldFeedback when="*" >Please Complete</FieldFeedback>
                                        </FieldFeedbacks>
                                    </div>
                                    <div style={{ display: this.state.showVisitorDontExist, color: 'red' }}><p>You have either captured the incorrect ID/Passport or have not registered. Please try again or click register</p></div>
                                    <div style={{ display: this.state.showVisitorExist, color: 'red' }}><p>A registration exists for the captured ID/Passport please login</p></div>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col xs={6}>
                                    <div className="form-group">
                                        <img src={security} style={{ width: '20px', padding: '0px 0px 0px 0px', margin: '0px 3px 0px 0px' }} />

                                        <input
                                            name="password"
                                            id="password"
                                            type="password"
                                            placeholder="Password"
                                            className="form-control+matching-text-style"
                                            required onChange={this.handleChangePassword}
                                            style={{ width: '400px' }}
                                        />
                                        <FieldFeedbacks for="password">
                                            <FieldFeedback when="*" >Please Complete</FieldFeedback>
                                        </FieldFeedbacks>
                                    </div>
                                    <div style={{ display: this.state.showLogonError, color: 'red' }}><p>Incorrect username or password captured, please try again</p></div>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col xs={6}>
                                    <button className="btn btn-secondary" style={{ width: "200px", margin: '20px 0px 0px 0px' }}>Login</button>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col xs={6}>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    </div>
                </FormWithConstraints>


                <Modal
                    show={this.state.showUnauthorisedError}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>

                    <Modal.Body>
                        <p>
                            <span style={{ color: "red", fontWeight: "bold" }}>Unauthorised access</span>
                        </p>
                        <p>
                            <span>You are not authorised for access to this system.</span>
                        </p>
                        <p>
                            <button className="btn btn-danger" onClick={this.handleClose} >
                                Ok
                            </button>
                        </p>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

export default logon;