import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import TicketsComponent from "./ticketsComponent";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import region from "../location_white.png";
import logout from "../log-out white.png";
import plus from "../plus.png";
import chart from "../chart-bar white.png";
import { Link } from "react-router-dom";
import AdminUserComp from "./adminuserComponent";
// import adminTableComponent from "./admin.table";
import Modal from "react-bootstrap/Modal";
import { Redirect } from 'react-router';
import Cookies from "universal-cookie";
import rejectwhite from "../reject white.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import "./adminuser.css";

toast.configure();
const cookies = new Cookies();
const config = require('../config.json');

// const notify = ()=>{
//   // inbuilt-notification
//   toast.warning('Danger')
//   // inbuilt-notification
//   toast.success("User successfully deleted!")
//   // inbuilt-notification
//   toast.info('GeeksForGeeks')
//   // inbuilt-notification
//   toast.error('Runtime error')
//   // default notification
//   toast('Hello Geeks')
// }

let data = [];

class Tickets extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.childKey = 0;

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      directTo: "/",
      errMsg_region: "none",
      showDeleteMessage: false,
      deleteId: "",
      editId: "",
      // data: this.getAllTickets(),
      something: true

    };
    
  }

  _isMounted = false;
  // const params = {
  //   id: this.state.id,
  //   usid: localStorage.getItem('usersession')
  // };

  handleClose() {
    this.setState({ showDeleteMessage: false });
  }

  handleSelectContact = (mail) => {
    alert(mail);
  };

  handleDeleteUser = (id, event) => {
    event.preventDefault();
    this.setState({ deleteId: id });
    this.setState({ showDeleteMessage: true });
  }

  handleEditUser = (id, event) => {
    event.preventDefault();
    this.setState({ editId: id });
    this.setState({ redirect: true });
    this.setState({ directTo: "/adminUserEdit" })
  }

  componentDidMount() {

    this._isMounted = true;
    // this.setState({ data: [] });

    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }

    // let timer = setInterval(() => {
    //   this.setState(({something}) => ({something: !something}));
    // }, 1000);
    // setTimeout(() => {
    //   clearInterval(timer);
    //   timer = 0;
    // }, 10000);

    this.getAllTickets();

  }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  // DeleteUser() {
  //   // alert(this.state.deleteId);
  //   this.deleteAdmin();
  // }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g,"_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g," ");
    return string;
  }

  getAllTickets = async () => {

    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {

      console.log('Administrator - Load all');

      try {
        const param = {
          username: localStorage.getItem('username'),
          usid: localStorage.getItem('usersession')
        };

        const response = await axios.post(`${config.api.invokeUrl}/adminuserscountry/`, param, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        })

        this.setState({ setTickets: response.data });
        this.state.data = response.data;
        this.setState({ errMsg_region: "block" });

      } catch (err) {
        console.log("Unable to load users for country");
      }
    } else {

      console.log('Load only for country: '+ this.state.adminCountry);

      try {
        const param = this.removeWhiteSpace(this.state.adminCountry);
        const response = await axios.get(`${config.api.invokeUrl}/adminuserscountry/` + param, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        })
        
        this.setState({ setTickets: response.data });
        this.state.data = response.data;
        console.log(response.data);
        this.setState({ errMsg_region: "block" });

      } catch (err) {
        console.log("Unable to load users for country");
      }
    }
  };

  deleteAdmin = async () => {
    try {
      const params = {
        "id": this.state.deleteId.toLowerCase()
      };
      // console.log("deleteAdmin: " + this.state.deleteId.toLowerCase());

      const res = await axios.post(`${config.api.invokeUrl}/adminuserd`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ deleteId: "" });
      this.setState({ showDeleteMessage: false });
      this.forceUpdate();
      this.getAllTickets();

      toast.success('User successfully deleted');

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  //const reportTickets = tickets.filter(ticket => ticket.status === "completed");
  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          id: this.state.editId,
          isAdminRole: this.state.isAdminRole,
          tickets: this.state.setTickets
        }
      }} />;
    }

    // ++this.childKey;

    //style={{display: this.state.showStore ? 'block' : 'none' }}
    //display={!this.state.isAdminRole} disabled={!this.state.isAdminRole}
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton" disabled={!this.state.isAdminRole}><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
              <tbody>
                <tr>
                  <td><span className="pageTitle">User Administration</span></td>
                  <td style={{ textAlign: "right", verticalAlign: "middle" }}><Link to={`/adminUserNew/`}><button style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Create User | <img src={plus}></img></button></Link></td>
                </tr>
              </tbody>
            </table>
            <br />
            <div style={{ display: this.state.errMsg_region }}>
            {/* {this.state.something} */}
              <AdminUserComp onCreate={() => this.getAllTickets()}
                handleDeleteUser={this.handleDeleteUser}
                handleEditUser={this.handleEditUser}
                tickets={this.state.data}
                key={this.childKey}
              />

              {/* <adminTableComponent 
                // dataToTableComponent={dataToTableComponent}
                handleDeleteUser = {this.handleDeleteUser} 
                handleEditUser = {this.handleEditUser}
                userData = {this.state.data}
                // searched = {this.state.searched}
                // status = {this.state.status}
              /> */}

                  <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    />
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDeleteMessage}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Delete User</span>
            </p>
            <p>
              <span>Are you sure you want to delete this user?</span>
            </p>
            <p>
              <button className="btn btn-secondary" onClick={this.deleteAdmin} style={{ margin: '0px 10px 0px 0px' }} >
                Yes
              </button>
              <button className="btn btn-danger" onClick={this.handleClose} >
                No
              </button>
            </p>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default Tickets;