import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

export default class regionComp extends Component {
  
  // handleEditSave = event => {
  //   event.preventDefault();
  //   this.props.handleSelectContact(this.props.mail);
  // }

  // componentDidMount() {
  //     console.log(this.props.tickets);
  // }

    render() {
      return (
        <div className="container" style={{height: "700px", overflowY: "scroll"}}>
            { this.props.tickets === undefined  ? (
            "Loading..."
            ) : (
            <table className="table" style={{ margin: '0px 0px 50px 0px'  }}>
                <thead style={{backgroundColor: '#4A4D4E', color: 'white'}}>
                <tr>              
                    <th scope="col">Region Name</th>                       
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                { this.props.tickets.map(ticket => (
                    <tr key={ticket.Id}>
                        <td>{ticket.regionname}</td>
                        <td style={{width: '220px'}}>
                            <button onClick={event => this.props.handleEditRegion(ticket.Id, ticket.regionname, ticket.country, event)} style={{margin: '0px 0px 0px 0px'}} className="btn btn-secondary">Edit</button>
                            <button onClick={event => this.props.handleDeleteRegion(ticket.Id, event)} style={{margin: '0px 0px 0px 10px'}} className="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            )}
        </div>  

      )
    }
  }
  