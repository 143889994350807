import React from 'react';
import Logon from './components/logon';
import Dashboard from './components/dashboard';
import ticketsComponent from './components/ticketsComponent';
import adminUsers from './components/adminUsers';
import adminuseredit from './components/adminUserEdit';
import adminusernew from './components/adminUserNew';
import otpfields from './components/otpfields';
import surveyadmin from './components/surveyadmin';
import countryAdmin from './components/countryAdmin';
import countryNew from './components/countryNew';
import countryEdit from './components/countryEdit';
import regionAdmin from './components/regionAdmin';
import regionNew from './components/regionNew';
import regionEdit from './components/regionEdit';
import buildingAdmin from './components/buildingAdmin';
import buildingNew from './components/buildingNew';
import buildingEdit from './components/buildingEdit';
import surveyNew from './components/surveyNew';
import Report from './components/report';
import SurveyEdit from './components/surveyEdit';
import rejectionsAdmin from './components/rejectionsAdmin';
import surveyResult from './components/surveyresults';
import employeeAdmin from './components/employee';
import employeeEdit from './components/employeeEdit';
import employeeNew from './components/employeeNew';
import fileUpload from './components/fileUpload';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Footer from './components/footer';
import Header from './components/header';

function App() {
  return (
    <div className="main_div">

      <Router>
        <div className="page-container">
          <div className="content-wrap">
            <Switch>
              <Route exact path='/' component={Logon} />
              <Route exact path='/dashboard' component={Dashboard} />
              <Route exact path='/ticketsComponent' component={ticketsComponent} />
              <Route exact path='/adminUsers' component={adminUsers} />
              <Route exact path='/adminUserEdit' component={adminuseredit} />
              <Route exact path='/adminUserNew' component={adminusernew} />
              <Route exact path='/otpfields' component={otpfields} />
              <Route exact path='/surveyadmin' component={surveyadmin} />
              <Route exact path='/countryAdmin' component={countryAdmin} />
              <Route exact path='/countryNew' component={countryNew} />
              <Route exact path='/countryEdit' component={countryEdit} />
              <Route exact path='/regionAdmin' component={regionAdmin} />
              <Route exact path='/regionNew' component={regionNew} />
              <Route exact path='/regionEdit' component={regionEdit} />
              <Route exact path='/buildingAdmin' component={buildingAdmin} />
              <Route exact path='/buildingNew' component={buildingNew} />
              <Route exact path='/buildingEdit' component={buildingEdit} />
              <Route exact path='/surveyNew' component={surveyNew} />
              <Route exact path='/report' component={Report} />
              <Route exact path='/surveyEdit' component={SurveyEdit} />
              <Route exact path='/rejectionsAdmin' component={rejectionsAdmin} />
              <Route exact path='/surveyresults' component={surveyResult} />
              <Route exact path='/employee' component={employeeAdmin} />
              <Route exact path='/employeeEdit' component={employeeEdit} />
              <Route exact path='/employeeNew' component={employeeNew} />
              <Route exact path='/fileUpload' component={fileUpload} />
            </Switch>
          </div>
        </div>
      </Router>

    </div>

  )
}

export default App;
