import React, { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy, useGlobalFilter, useFilters, useAsyncDebounce, state } from 'react-table'
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import "react-table/react-table.css";
import Searchbar from './searchBar';
import DropDown from './dropDown';
import profilePic from "../profilePic.png";


// import makeData from './makeData';
let data = [];

export default function PaginationTableComponent({ticketsAll, handleDeleteProduct, handleEditProduct}) {

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Image",
      //   accessor: "visitorimage",
      //   Cell: props => {
      //     return (
      //       props.value === ''
      //         ?<img className="img-report" src={profilePic} />
      //         :<img className="img-report" src={`${props.value}`} />
      //     );
      //   },
      //   disableSortBy: true,
      // },
      {
        Header: "First Name",
        accessor: "visitorname",
        sortType: 'basic',
      },
      {
        Header: "Last Name",
        accessor: "visitorlastname",
        sortType: 'basic',
        enableResizing: true,
      },
      {
        Header: 'Mobile',
        accessor: 'visitormobile',
        filter: 'includes',
        sortType: 'basic',
      },
      {
        Header: 'Email Address',
        id: 'email',
        accessor: 'visitoremail',
        disableSortBy: true,
        enableResizing: true,
      },
      {
        Header: 'Company',
        accessor: 'visitorcompany',
        filter: 'includes',
        sortType: 'basic',
      },
      {
        Header: 'Visitor Type',
        accessor: 'visitortype',
        filter: 'includes',
        sortType: 'basic',
      },
      {
        Header: 'Building',
        accessor: 'building',
        filter: 'includes',
        sortType: 'basic',
        enableResizing: true,
      },
      {
        Header: 'Date & Time',
        accessor: 'visitdate',
        sortType: 'basic',
      },
      {
        Header: 'Duration',
        accessor: 'visittime',
        disableSortBy: true,
      },
      // {
      //   Header: 'ID Number',
      //   accessor: 'visitorid',
      //   disableSortBy: true,
      // },
      {
        Header: 'Host',
        accessor: 'hostname',
        sortType: 'basic',
      },
      // {
      //   Header: 'Action',
      //   id: 'id',
      //   accessor: 'id',
      //   disableSortBy: true,
      //   Cell: ({ cell: { value } }) => {
      //     // alert(id)
      //     return <>
      //       <button onClick={event => handleEditProduct(value, event)} style={{margin: '0px 0px 0px 0px'}} className="btn btn-primary">View</button>
      //       <button onClick={event => handleDeleteProduct(value, event)} style={{margin: '0px 0px 0px 10px'}} className="btn btn-danger">Delete</button>
      //     </>
      //   }
      // },
    ],
    []
  )

    // COLUMNS FOR TEST
    // const columns = React.useMemo(
    //   () => [
    //     {
    //       Header: 'Name',
    //       columns: [
    //         {
    //           Header: 'First Name',
    //           accessor: 'firstName',
    //         },
    //         {
    //           Header: 'Last Name',
    //           accessor: 'lastName',
    //         },
    //       ],
    //     },
    //     {
    //       Header: 'Info',
    //       columns: [
    //         {
    //           Header: 'Age',
    //           accessor: 'age',
    //         },
    //         {
    //           Header: 'Visits',
    //           accessor: 'visits',
    //         },
    //         {
    //           Header: 'Status',
    //           accessor: 'status',
    //         },
    //         {
    //           Header: 'Profile Progress',
    //           accessor: 'progress',
    //         },
    //       ],
    //     },
    //   ],
    //   []
    // )

    
    data = ticketsAll;
    
    // console.log(ticketsAll);
    // console.log(data);
    // const data = React.useMemo(() => makeData(5000), [])
    // JSON.stringify(data);
    // console.log(JSON.stringify(data));

    // const {
    //   getTableBodyProps,
    //   getTableProps,
    //   headerGroups,
    //   rows,
    //   prepareRow,
    // } = useTable({ columns, data, getRowId });


    const CustomNoDataComponent = ({ state, ...rest }) => {
      if (state.isLoading) {
        return null;
      }
      return <div className="rt-noData">No rows found</div>
    }


    

    // if (!data.length)
    //       return null;


          // minRows={0}

    return (
      data.length > 0 ?
      <Table style={{ height: '98vh', textAlign: "center", verticalAlign: "middle" }} columns={columns} data={data} 
        NoDataComponent={ CustomNoDataComponent }
      /> : <div style={{ margin: '120px 0px 0px 0px', textAlign: "center", verticalAlign: "middle" }}><span>No results</span></div>
    )
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div class="d-inline-flex p-2 bd-highlight">
      <div className="input-group mb-2" >
          <div className="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Search</span>
          </div>
          <input
            className="form-control"
            // style={{ width: '300px', height: '30px' }}
            value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder="..." 
            aria-label="..." 
            aria-describedby="basic-addon1"
          />
      </div>
    </div>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      className="form-control"
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}
 
function Table({ columns, data }) {
  const [rowdata, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [showTable, toggleShowTable] = useState(true);

  // const refreshTable = () => {
  //   toggleShowTable(false);
  //   setTimeout(() => toggleShowTable(true), 0.1);
  // };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      rows,
      setFilter,

      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },

      // Search / Filtering Props
      preGlobalFilteredRows,
      setGlobalFilter
  } = useTable(
      {
          defaultColumn,
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 30 }
          // filterTypes
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
  )

  // Update the state when input changes
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter( data.id, value); // Update the filter. Now our table will filter and show only the rows which have a matching value
    setFilterInput(value);
  };

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

// Input element
{/* <input
  value={filterInput}
  onChange={handleFilterChange}
  placeholder={"Search applicant email"}
/> */}

  // useEffect(() => {
  //   setSkipPageReset(false);
  // }, [rowdata]);

  // const onItemClick = (e) => {
  //   console.log("e", e);
  //   if (e === "all") {
  //     setFilteredData(rowdata);
  //   } else {
  //     const result = rowdata.filter((item) => item.gender === e);

  //     setFilteredData(result);
  //   }
  // };

  // const onSearchbarChange = (e) => {
  //   const value = e.target.value;

  //   if (value === "") {
  //     setFilteredData(rowdata);
  //   } else {
  //     if (filteredData.length > 0) {
  //       const result = filteredData.filter((item) => item.email === value);

  //       setFilteredData(result);
  //     } else {
  //       const result = rowdata.filter((item) => item.email === value);

  //       setFilteredData(result);
  //     }
  //   }
  // };

  // handleEditSave = event => {
  //   event.preventDefault();
  //   this.props.handleSelectContact(this.props.mail);
  // }

  // Render the UI for your table
  return (
    <React.Fragment>
      {/* <div className="justify-center">
            <Searchbar onChange={onSearchbarChange} />
            <DropDown
              options={[
                { label: "All", value: "all" },
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
              ]}
              title={"Select Gender"}
              onItemClick={onItemClick}
            />
          </div> */}
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table className="table" {...getTableProps()} >
        <thead style={{ backgroundColor: '#4A4D4E', color: 'white', fontSize: '14px' }}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span></th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{ fontSize: '12px' }} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div class="d-flex justify-content-center">
        <select
          style={{
            padding: "7px",
            borderRadius: "3px",
            border: "1px solid #007bff"
          }}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[20, 50, 100, 200].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>{" "}
        <button
          className="btn btn-outline-primary"
          style={{ marginTop: "-4px", marginLeft: "8px", marginRight: "8px" }}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>{" "}
        <button
          className="btn btn-outline-primary"
          style={{ marginTop: "-4px" }}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>{" "}
        <span style={{ marginLeft: "8px", marginRight: "8px" }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <button
          className="btn btn-outline-primary"
          style={{ marginTop: "-4px" }}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>{" "}
        <button
          className="btn btn-outline-primary"
          style={{ marginTop: "-4px", marginLeft: "8px", marginRight: "8px"  }}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
      </div>
      <br />
      <div>Showing the first {pageSize} results of {rows.length} rows</div>
    </React.Fragment>
    
  )
}

