import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import writer from "../rank-writer.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import help from "../help-circle.png";
import cost from "../cost-control.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";


const config = require("../config.json");

const options = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];


class surveyedit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      redirect: false,
      redirectTo: "/",
      surveyId: this.props.location.state.surveyId,
      title: this.props.location.state.title,
      question: this.props.location.state.question,
      country: this.props.location.state.country,
      status: this.props.location.state.status,
      errMsg_title: "none",
      errMsg_question: "none",
      errMsg_country: "none",
      errMsg_status: "none",
      errMsg_update: "",
      loading: "Loading...",
      statusDisabled: false,
      countryDisabled: false
    };

    // id: this.props.location.state.id,
    //         title: this.props.location.state.title,
    //         question: this.props.location.state.question,
    //         status: this.props.location.state.status,
  }

  onNameChange = event => this.setState({ title: event.target.value });

  onCountryChange = event => {
    this.setState({ country: event.value });
    this.setState({ errMsg_country: "none" });
  }

  onQuestionChange = event => {
    const questionvalue = event.target.value;

    if (questionvalue.length > 160) {
      //alert("too long");
    } else {
      this.setState({ question: event.target.value });
    }
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      const regiondata = response.data;
      const options = regiondata.map(d => ({
        "value" : d.Id,
        "label" : d.countryname
      }))

      this.setState({selectOptions: options})
      this.setState({ loading: "" });
      
      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  // getSurvey = async () => {
  //   try {

  //     const params = {
  //       "Id": this.state.id
  //     };

  //     const res = await axios.get(`${config.api.invokeUrl}/surveymasters/{id}`, params, {
  //       "headers": {
  //         "x-api-key": `${config.api.xapikey}`
  //       }
  //     });
  //     console.log(res.data[0]);
  //     this.setState({ id: res.data[0].id });
  //     this.setState({ title: res.data[0].title });
  //     this.setState({ question: res.data[0].question });
  //     this.setState({ country: res.data[0].country });
  //     this.setState({ status: res.data[0].statussurvey });
  //     this.setState({ loading: "" });
  //   } catch (err) {
  //     console.log(`An error has occurred: ${err}`);
  //   }
  // };

  onAddVisitorRoleChange = event => {
    this.setState({ role: event.value });
    this.setState({ errMsg_role: "none" });
  }

  onStatusChange = event => {
    this.setState({ status: event.value });
    this.setState({ errMsg_status: "none" });
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value,
    });
  }

  componentDidMount() {
    this.setState({ errMsg_update: "" });
    this.setState({ loading: "Loading..." });
    this.getAllCountries();

    // this.getSurvey();
  }

  handleUpdateSurvey = e => {
    e.preventDefault();

    let formValid = true;

    if (this.state.title === '') {
      this.setState({ errMsg_title: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_title: "none" });
    }

    if (this.state.question === '') {
      this.setState({ errMsg_question: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_question: "none" });
    }

    if (this.state.country === '') {
      this.setState({ errMsg_country: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_country: "none" });
    }

    if (this.state.status === '') {
      this.setState({ errMsg_status: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_status: "none" });
    }

    if (formValid === true) {
      console.log('form is valid: submit');
      this.updateAdmin();
    }
  }

  updateAdmin = async () => {
    try {
      const params = {
        "id": this.state.surveyId,
        "title": this.state.title,
        "question": this.state.question,
        "country": this.state.country,
        "statussurvey": this.state.status
      };

      await axios.patch(`${config.api.invokeUrl}/surveymasters/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ redirectTo: "/surveyadmin"});
      this.setState({ redirect: true});
      // this.setState({ errMsg_update: "Survey updated." })
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Edit Survey</h5>
            <br></br>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>
              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={this.handleUpdateSurvey}
                noValidate>

                <div className="container">

                  <div className="form-row">
                    <span style={{ color: 'red' }}>{this.state.loading}</span>
                  </div>

                  <div className="form-row">
                    <img src={writer} style={{ margin: '0px 5px 0px 0px' }} />

                    <input
                      name="title"
                      id="title"
                      type="text"
                      placeholder="Survey Title"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.title}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_title, color: 'red' }}>Please complete.</span>
                  </div>
                  <div className="form-row">
                    <p></p>
                  </div>
                  <div className="form-row">
                    <img src={help} style={{ margin: '0px 5px 0px 0px', height: "40px", width: "40px" }} />
                    <textarea
                      name="question"
                      id="question"
                      placeholder="Type Question"
                      className="form-control+matching-text-style"
                      onChange={this.onQuestionChange}
                      cols="35"
                      rows="5"
                      style={{ resize: "none" }}
                      value={this.state.question}>
                    </textarea>
                  </div>
                  <div className="form-row">
                    <span style={{ fontSize: "10px", margin: '0px 0px 0px 45px' }}>Max characters : 160</span>
                  </div>
                  <div className="form-row">
                    <span style={{ margin: '0px 0px 10px 45px', display: this.state.errMsg_question, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.countryDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select country "
                        onChange={this.onCountryChange}
                        required
                        styles={style}
                        options={this.state.selectOptions}
                        value={{ value: this.state.country, label: this.removeUnderscore(this.state.country)}}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={cost} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.statusDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={options}
                        placeholder="Status "
                        onChange={this.onStatusChange}
                        required
                        styles={style}
                        value={{ label: this.state.status, value: this.state.status }}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_status, color: 'red' }}>Please complete.</span>
                    </div>

                  </div>
                  <div className="form-row">
                    <p></p>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/surveyadmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>

                  <div className="form-row">
                    <p style={{ color: 'red' }}>{this.state.errMsg_update}</p>
                  </div>

                </div>

              </FormWithConstraints>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default surveyedit;