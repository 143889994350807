import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import adminUsers from "./adminUsers";
  
class dashboard extends Component {
    constructor(props, context) {
		super(props, context);

        this.onSelectAdminUsers = this.onSelectAdminUsers.bind(this); 

		this.state = {
            redirect: false,
            redirectTo: "/",
            showVisitorDontExist: "none",
            showVisitorExist: "none"
		};
    }


    onSelectAdminUsers() {
        this.setState({redirectTo: "/adminUsers"});
        this.setState({redirect: true});                    
    }
    

   render() { 
    if (this.state.redirect) {
        return <Redirect push to={{
            pathname: this.state.redirectTo
          }} />;
    }
        return ( 
            <div>
                <div className="top">
                    <Header></Header>
                </div>
                <div className="left">
                    <button className="menubutton" style={{backgroundColor: '#7FA5D0'}}><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px'  }}  />User Administration</button>
                    <button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px'  }} onClick={this.onSelectAdminUsers} />Survey Administration</button>
                    <button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px'  }}  />Building Administration</button>
                    <button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px'  }}  />Logout</button>
                </div>
                <div className="main">                    
                    <div style={{margin: '0px 0px 0px 10px'}}>                    
                        <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>User Administration</h5>                                                 
                        <br></br>                            
                        <adminUsers></adminUsers>
                    </div>                     
                </div>
                
                                                                                
            </div>              
            );
    }
}
 
export default dashboard;