import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import filter from "../filter white.png";
import profilePic from "../profilePic.png";

const cookies = new Cookies();
let filterValue = "";

export default class employeeComponent extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: false,
      filter: "",
      userRoles: cookies.get('DVAdminRole')
    };
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    
    if (stringfromdb === '' || stringfromdb === undefined || stringfromdb === null) {
      var string = "-";
      return string;
    } else {
      var string = stringfromdb;
      string = string.replace(/_/g, " ");  
      return string;
    }
  }

  onFilterChange = event => {
    filterValue = event.target.value;
    this.setState({ filter: event.target.value });
    // console.log(this.state.userRoles);
  }

  render() {
    return (

      <div className="main" style={{ width: "80%", height: "700px", overflowY: "scroll" }}>
        {this.props.employees === undefined ? (
          "Loading..."
        ) : (
          <table className="table" style={{ margin: '0px 0px 50px 0px' }}>
            <thead style={{ backgroundColor: '#4A4D4E', color: 'white' }}>
              <tr>
                <th scope="col">-</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Department</th>
                <th scope="col">Region</th>
                <th scope="col">Country</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="7" style={{ backgroundColor: "#00000029" }}>
                  <input onChange={this.onFilterChange} type="text" className="form-control" placeholder="Filter by employee name or surname" style={{ width: "100%" }}></input>
                </td>
                <td style={{ backgroundColor: "#00000029" }}>
                  <button className="button button2" onClick={event => this.props.handleFilterEmployee(this.state.filter, event)}><img src={filter}></img><br /></button>
                </td>
              </tr>
              {this.props.employees.map(employee => (
                <tr key={employee.id}>
                  {
                    employee.photo === ''
                      ? <td><img className="img-circularx" src={profilePic} /> </td>
                      : <td><img className="img-circularx" src={`${employee.photo}`} /> </td>
                  }
                  <td>{employee.name}</td>
                  <td>{employee.lastname}</td>
                  <td>{employee.email}</td>
                  <td>{employee.department}</td>
                  <td>{this.removeUnderscore(employee.region)}</td>
                  <td>{this.removeUnderscore(employee.country)}</td>
                  {/* <td>{this.removeUnderscore(employees.country)}</td> */}
                  <td style={{ width: '230px' }}>
                    <button onClick={event => this.props.handleEditEmployee(employee.id, event)} style={{ margin: '0px 0px 0px 0px' }} className="btn btn-secondary">Edit</button>
                    <button onClick={event => this.props.handleDeleteEmployee(employee.id, event)} style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

    )
  }
}
