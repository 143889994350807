import React, { Component } from 'react';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import './otpfields.css';
import { Redirect } from 'react-router';
import vodbanner from "../vodacom logo.png";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const configFile = require('../config.json');

class otpFields extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      otp: '',
      redirect: false,
      Mobile: this.props.location.state.mobile,
      showOTPError: "none",
      numInputs: 6,
      separator: '',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      minLength: 0,
      maxLength: 40,
      newOTP: '',
      target: '/adminUsers',
      userRole: cookies.get('DVAdminRole')
    };
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleCancel() {
    this.setState({ redirect: true });
    this.setState({ target: "/" });
  }

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;

        console.error(
          `Please enter a value between ${minLength} and ${maxLength}`
        );
      }
    }

    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  handleSubmit = e => {
    e.preventDefault();
    //alert(this.state.otp);
    //alert(this.state.newOTP);
    if (this.state.otp == this.state.newOTP) {
      if ((this.state.userRole === "Building Admin and Reception User") || (this.state.userRole === "Security User")) {
        this.setState({ target: "/report" })
      }
      this.setState({ redirect: true });
    } else {
      //alert("not EQ");
      this.setState({ showOTPError: "block" });
      this.setState({ hasErrored: true });
    }
  };

  componentDidMount = () => {
    this.sendMessage();
  }

  resendOTP = e => {
    e.preventDefault();
    this.sendMessage();
  };

  sendMessage = () => {
    const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    const otp = new OTPClient(secret);
    const token = otp.getToken();
    const mobile = cookies.get('DVAdminMobile');

    this.setState({ newOTP: token }, function () {
      // console.log(this.state.newOTP);
      // console.log(mobile);
    });

    //alert(token);
    var data = JSON.stringify({
      "message": "Vodacom Digital Visitor " + token,
      "token": `${configFile.api.SMStoken}`,
      "destination": mobile
    });

    var config = {
      method: 'post',
      url: `${configFile.api.SMSUrl}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      minLength,
      maxLength,
      newOTP,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.target,
        state: {
          id: this.state.Id,
          region: this.state.Region,
          buildings: this.state.Buildings
        }
      }} />;
    }
    return (
      <div>
        <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
          <p><img src={vodbanner} alt="Vodacom Banner"></img></p>

          <p>
            <span style={{ fontSize: '11px' }}>Sent to the mobile number for the captured information</span>
          </p>
          <p>
            <span style={{ display: this.state.showOTPError, color: 'red' }}>Incorrect OTP captured, please click resend and try again</span>
          </p>
          <br />
          <p /><h3>Capture OTP</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="containerOTP">
              <div className="view">
                <div className="card">
                  <OtpInput
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "5px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "2px solid rgba(0,0,0,0.3)",
                    }}
                    numInputs={numInputs}
                    isDisabled={isDisabled}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={this.handleOtpChange}
                    separator={<span>{separator}</span>}
                    isInputNum={isInputNum}
                    shouldAutoFocus
                    value={otp}
                  />

                  <div >
                    <button
                      style={{ margin: '10px 0px 0px 0px' }}
                      className="btn btn-danger"
                      onClick={this.handleCancel}>
                      Cancel
                    </button>
                    <button
                      style={{ margin: '10px 0px 0px 10px' }}
                      className="btn btn-secondary"
                      disabled={otp.length < numInputs}>
                      Submit
                    </button>
                    <br />
                    <button style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <br />
      </div>
    );
  }
}

export default otpFields;