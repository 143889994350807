import React, { Component, Fragment }  from 'react';
import { Link } from "react-router-dom";

export default class rejectionComp extends Component {
    
    handleEditSave = event => {
        event.preventDefault();
        this.props.handleSelectContact(this.props.mail);
      }
    
      componentDidMount() {
        //  console.log(this.props.tickets);
      }

    render() {
      return (
        <div className="container" style={{height: "700px", overflowY: "scroll"}}>
            { this.props.tickets === undefined  ? (
            "Loading..."
            ) : (
            <table className="table" style={{ margin: '0px 0px 50px 0px'  }}>
                <thead style={{backgroundColor: '#4A4D4E', color: 'white', fontSize: "10px"}}>
                <tr>                                  
                    <th scope="col">Name</th>                       
                    <th scope="col">Surname</th>                       
                    <th scope="col">Contact Number</th>                       
                    <th scope="col">Captured Concerns</th>   
                    <th scope="col">Date &amp; Time</th>                                                              
                </tr>
                </thead>
                <tbody style={{fontSize: "10px"}}>
                    { 
                    this.props.tickets.map(ticket => (
                    <tr key={ticket.id}>
                        <td>{ticket.firstname}</td>                                                                                                               
                        <td>{ticket.lastname}</td>
                        <td>{ticket.contactno}</td>                                                                                                                                                                                                                                                                                                                                                                                                                    
                        <td>{ticket.message}</td>  
                        <td>{ticket.datecreated}</td>                                                                                                             
                    </tr>
                    )
                    )}
                </tbody>
            </table>
            )}
        </div>  

      )
    }
  }
  