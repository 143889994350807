import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import AWS from "aws-sdk";
import TicketsComponent from "./ticketsComponent";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import region from "../location_white.png";
import logout from "../log-out white.png";
import plus from "../plus.png";
import chart from "../chart-bar white.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import EmployeeComp from "./employeeComponent";
// import adminTableComponent from "./admin.table";
import Modal from "react-bootstrap/Modal";
import { Redirect } from 'react-router';
import Cookies from "universal-cookie";
import rejectwhite from "../reject white.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingSpinner from './loadingSpinner';
import * as XLSX from 'xlsx';
import configData from "../config.json";
import FileUpload from './fileUpload';
// import "./adminuser.css";

toast.configure();
const cookies = new Cookies();
const config = require('../config.json');

// const notify = ()=>{
//   // inbuilt-notification
//   toast.warning('Danger')
//   // inbuilt-notification
//   toast.success("User successfully deleted!")
//   // inbuilt-notification
//   toast.info('GeeksForGeeks')
//   // inbuilt-notification
//   toast.error('Runtime error')
//   // default notification
//   toast('Hello Geeks')
// }

let data = [];

class Employees extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDeleteEmployee = this.handleDeleteEmployee.bind(this);
    this.handleEditEmployee = this.handleEditEmployee.bind(this);
    this.handleFilterEmployee = this.handleFilterEmployee.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.childKey = 0;

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      directTo: "/",
      errMsg_region: "none",
      showDeleteMessage: false,
      showImportEmployee: false,
      employeeId: "",
      employees: [],
      isLoading: false,
      file: "",
      fileStatus: ""
      // [ 'initial', 'uploading', 'success', 'fail' ]
    };

  }

  _isMounted = false;
  // const params = {
  //   id: this.state.id,
  //   usid: localStorage.getItem('usersession')
  // };

  handleClose() {
    this.setState({ showDeleteMessage: false });
    this.setState({ showImportEmployee: false });
  }

  handleSelectContact = (mail) => {
    alert(mail);
  };

  handleDeleteEmployee = (id, event) => {
    event.preventDefault();
    //this.setState({ employeeId: id });

    this.setState({ employeeId: id }, () => {

      console.log(this.state.employeeId)

    });

    this.setState({ showDeleteMessage: true });
  }

  handleEditEmployee = (id, event) => {
    event.preventDefault();

    this.setState({ employeeId: id }, () => {

      //console.log(JSON.stringify(id));

    });

    this.setState({ redirect: true });
    this.setState({ directTo: "/employeeEdit" })
  }

  // handleImportEmployeeList = (event) => {
  //   // event.preventDefault();
  //   // event.preventDefault();
  //   console.log('import clicked');

  //   this.setState({ showImportEmployee: true });
  // }

  componentDidMount() {

    this._isMounted = true;
    // this.setState({ data: [] });

    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {
      //this.setState({ isAdminRole: true });
      this.setState({ isAdminRole: true }, () => {

      });

    }

    // let timer = setInterval(() => {
    //   this.setState(({something}) => ({something: !something}));
    // }, 1000);
    // setTimeout(() => {
    //   clearInterval(timer);
    //   timer = 0;
    // }, 10000);

    this.getAllEmployees();

  }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  // DeleteUser() {
  //   // alert(this.state.deleteId);
  //   this.deleteAdmin();
  // }

  downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  // Function to upload file to s3
  uploadFile = async () => {

    this.setState({ isLoading: true });
    this.setState({ fileStatus: 'initial' });

    const S3_BUCKET = configData.api.s3Bucket;
    const REGION = configData.api.awsRegion;

    AWS.config.update({
      accessKeyId: configData.api.awsAccessKeyId,
      secretAccessKey: configData.api.secretAccessKey,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: this.state.file.name,
      Body: this.state.file,
    };

    // Uploading file to s3

    var upload = s3

      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        this.setState({ fileStatus: 'uploading ' + parseInt((evt.loaded * 100) / evt.total) + "%" });
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // File successfully uploaded
      // alert("File uploaded successfully.");
      this.setState({ fileStatus: 'success' });
      this.setState({ isLoading: false });
    });
  };

  // Function to handle file and store it to file state
  handleFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // Changing file state
    this.setState({file: file});
  };

  handleFilterEmployee = (filter, event) => {
    event.preventDefault();
    this.setState({ filter: filter });
    if (filter === "") {
      this.getAllEmployees();
    } else {
      this.getFilterEmployee(filter);
    }
  }

  getAllEmployees = async () => {

    this.setState({ isLoading: true });

    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {

      try {
        const param = {
          username: localStorage.getItem('username'),
          usid: localStorage.getItem('usersession')
        };

        const response = await axios.post(`${config.api.invokeUrl}/employee/`, param, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        })

        this.setState({ employees: response.data });
        this.state.data = response.data;

        data = response.data;

        this.setState({ errMsg_region: "block" });

      } catch (err) {
        this.setState({ isLoading: false });
        console.log("Unable to load all employee");
      }

      this.setState({ isLoading: false });

    } else {

      // console.log('Load only for country: '+ this.state.adminCountry);

      this.setState({ isLoading: true });

      try {
        const param = this.removeWhiteSpace(this.state.adminCountry);
        const response = await axios.get(`${config.api.invokeUrl}/employeecountry/` + this.state.adminCountry, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        })

        this.setState({ employees: response.data });
        this.state.data = response.data;
        
        data = response.data;

        this.setState({ errMsg_region: "block" });

      } catch (err) {
        this.setState({ isLoading: false });
        console.log("Unable to load employees for country" + this.state.adminCountry);
      }

      this.setState({ isLoading: false });
    }
  };

  getFilterEmployee = async (filter) => {
    let objResult = '';
    const params = {
      searchString: filter.toLowerCase()
    };

    // console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/employeesearch/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(res.data);

      objResult = JSON.stringify(res.data);
      this.setState({ setTickets: JSON.parse(objResult) });

      //this.setState({ setTickets: response.data });
      this.state.data = res.data;
      // console.log(this.setTickets);
      this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  deleteEmployee = async (employeeId) => {
    try {
      const params = {
        //"id": this.state.deleteId.toLowerCase()
        "id": this.state.employeeId
      };

      console.log(params);

      // const res = await axios.post(`${config.api.invokeUrl}/adminuserd`, params, {
      const res = await axios.post(`${config.api.invokeUrl}/employeedelete/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      //this.setState({ deleteId: "" });
      this.setState({ showDeleteMessage: false });
      this.forceUpdate();
      this.getAllEmployees();

      toast.success('Employee successfully deleted');

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  //const reportTickets = tickets.filter(ticket => ticket.status === "completed");
  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          employeeId: this.state.employeeId,
          isAdminRole: this.state.isAdminRole
        }
      }} />;
    }

    const Result = this.state.fileStatus; 
    if (Result === "success") {
      // return <p>✅ File uploaded successfully!</p>;
      this.setState({ fileStatus: '✅ File uploaded successfully!' });
    } else if (Result === "fail") {
      // return <p>❌ File upload failed!</p>;
      this.setState({ fileStatus: '❌ File upload failed!' });
    } else if (Result === "uploading") {
      // return <p>⏳ Uploading selected file...</p>;
      this.setState({ fileStatus: '⏳ Uploading selected file...' });
    }

    // ++this.childKey;

    //style={{display: this.state.showStore ? 'block' : 'none' }}
    //display={!this.state.isAdminRole} disabled={!this.state.isAdminRole}
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton" disabled={!this.state.isAdminRole}><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
              <tbody>
                <tr>
                  <td><span className="pageTitle">Employee Administration</span></td>
                  <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                    <Link to={`/employeeNew/`}><button style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Add Employee</button></Link>
                    <button onClick={() => this.setState({ showImportEmployee: true })} style={{ margin: '0px 0px 0px 10px' }} className="btn btn-secondary">Import Employee List</button>
                    <button onClick={() => this.downloadExcel(data)} className="btn btn-secondary" style={{ margin: '0px 0px 0px 10px' }} >
                      Download as Excel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div style={{ display: this.state.errMsg_region }}>

            </div>
            <div>
              {/* {this.state.something} */}
              {this.state.isLoading ? <LoadingSpinner /> :
                <EmployeeComp onCreate={() => this.getAllEmployees()}
                  handleDeleteEmployee={this.handleDeleteEmployee}
                  handleEditEmployee={this.handleEditEmployee}
                  handleFilterEmployee={this.handleFilterEmployee}
                  employees={this.state.data}
                  key={this.childKey}
                />
              }

              {/* <adminTableComponent 
                // dataToTableComponent={dataToTableComponent}
                handleDeleteUser = {this.handleDeleteUser} 
                handleEditUser = {this.handleEditUser}
                userData = {this.state.data}
                // searched = {this.state.searched}
                // status = {this.state.status}
              /> */}

              <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
              />
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDeleteMessage}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Delete Employee</span>
            </p>
            <p>
              <span>Are you sure you want to delete this employee?</span>
            </p>
            <p>
              <button className="btn btn-secondary" onClick={this.deleteEmployee} style={{ margin: '0px 10px 0px 0px' }} >
                Yes
              </button>
              <button className="btn btn-danger" onClick={this.handleClose} >
                No
              </button>
            </p>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showImportEmployee}
          onHide={this.handleClose}
          animation={true}
          className="text-center"
          backdrop="static"
          keyboard={true}>

          <Modal.Header closeButton>
            <Modal.Title>Import Employee List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="sr-only">Upload pernod.csv file</Form.Label>
                <Form.Control type="file" onChange={this.handleFileChange} />
            </Form.Group>
            {this.state.file && (
              <section style={{ textAlign: 'left' }}>
                <h5 style={{ textAlign: 'left', color: 'green' }} >File details:</h5>
                <ul style={{ listStyle: 'none' }}>
                  <li>Name: {this.state.file.name}</li>
                  <li>Type: {this.state.file.type}</li>
                  <li>Size: {this.state.file.size} bytes</li>
                </ul>
              </section>
            )}
            {/* <Result Result /> */}
            
            <Form.Label>{this.state.fileStatus}</Form.Label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            {this.state.file && <Button variant="primary" onClick={this.uploadFile}>
              Import
            </Button>}
          </Modal.Footer>
        </Modal>

      </div>
    );
      
  };
  
}

export default Employees;