import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import writer from "../rank-writer.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import help from "../help-circle.png";
import cost from "../cost-control.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";


const config = require("../config.json");

const options = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];


class surveynew extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      redirect: false,
      redirectTo: "/",
      region: '',
      title: "",
      question: "",
      status: "",
      country: "",
      selectOptions: [],
      datecreated: new Date().toLocaleString(),
      errMsg_title: "none",
      errMsg_question: "none",
      errMsg_country: "none",
      errMsg_status: "none"
    };
  }

  onNameChange = event => this.setState({ title: event.target.value });
  
  onCountryChange = event => {
    this.setState({ country: event.value });
    this.setState({ errMsg_country: "none" });
  }

  onQuestionChange = event => {
    const questionvalue = event.target.value;

    if (questionvalue.length > 160) {
      //alert("too long");
    } else {
      this.setState({ question: event.target.value });
    }
  }

  onAddVisitorRoleChange = event => {
    this.setState({ role: event.value });
    this.setState({ errMsg_role: "none" });
  }

  onAddVisitorRegionChange = event => {
    this.setState({ status: event.value });
    this.setState({ errMsg_status: "none" });
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value,
    });
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      const regiondata = response.data;

      const options = regiondata.map(d => ({
        "value" : d.Id,
        "label" : d.countryname
      }))

      // console.log(options);

      this.setState({selectOptions: options})
      
      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  componentDidMount() {
    this.getAllCountries()
  }

  handleAddSurvey = async (id, event) => {
    event.preventDefault();
    let formValid = true;

    if (this.state.title === '') {
      this.setState({ errMsg_title: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_title: "none" });
    }

    if (this.state.question === '') {
      this.setState({ errMsg_question: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_question: "none" });
    }

    if (this.state.country === '') {
      this.setState({ errMsg_country: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_country: "none" });
    }

    if (this.state.status === '') {
      this.setState({ errMsg_status: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_status: "none" });
    }

    if (formValid === true) {
      // add call to AWS API Gateway add product endpoint here 
      const guid = uuidv4();

      try {
        const params = {
          "id": guid,
          "title": this.state.title,
          "question": this.state.question,
          "country": this.state.country,
          "statussurvey": this.state.status,
          "datecreated": this.state.datecreated
        };

        await axios.post(`${config.api.invokeUrl}/surveymasters/{id}`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });

        this.setState({ redirectTo: "/surveyadmin" });
        this.setState({ redirect: true });

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    }


  }


  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton" ><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Add New Survey</h5>
            <br></br>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>

              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={event => this.handleAddSurvey(this.state.email, event)}
                noValidate>

                <div className="container">
                  <div className="form-row" style={{ margin: '0px 0px 10px 0px' }}>

                    <img src={writer} style={{ margin: '0px 5px 0px 0px' }} />

                    <input
                      name="title"
                      id="title"
                      type="text"
                      placeholder="Survey Title"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                    />
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_title, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row">
                    <img src={help} style={{ margin: '0px 5px 0px 0px', height: "40px", width: "40px" }} />
                    <textarea
                      name="question"
                      id="question"
                      placeholder="Type Question"
                      className="form-control+matching-text-style"
                      onChange={this.onQuestionChange}
                      cols="35"
                      rows="5"
                      style={{ resize: "none" }}
                      value={this.state.question}>
                    </textarea>
                  </div>
                  <div className="form-row">
                    <span style={{ fontSize: "10px", margin: '0px 0px 0px 45px' }}>Max characters : 160</span>
                  </div>
                  <div className="form-row">
                    <span style={{ margin: '0px 0px 10px 45px', display: this.state.errMsg_question, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.countryDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select country "
                        onChange={this.onCountryChange}
                        required
                        styles={style}
                        options={this.state.selectOptions}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      >
                      </Select>
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={cost} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.regionDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={options}
                        placeholder="Status "
                        onChange={this.onAddVisitorRegionChange}
                        required
                        styles={style}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_status, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>
                  <div className="form-row">
                    <p></p>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/surveyadmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>
                </div>

              </FormWithConstraints>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default surveynew;