import React, { Component } from 'react';
import "./footer.css";


class Footer extends Component {
    constructor(props, context) {
        super(props, context);

       

    }
    
    state = {
       
      };

    render() { 
        return (
            <div>
                <div className="main-footer">
                 <div className="container">
                     <div>                        
                        <span></span>
                     </div>
                     <div style={{margin: '40px 0px 0px 0px'}}>                        
                        <span className="footer-font" ></span>                        
                     </div>
                 </div>
                  <div className="page-containerRight"></div>           
                </div>                    
            </div>                  
        );
    }
}
 
export default Footer;


