import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import region from "../location_white.png";
import logout from "../log-out white.png";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Redirect } from 'react-router';
import plus from "../plus.png";
import SurveyComponent from "./surveyComponent";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require('../config.json');
const cookies = new Cookies();
let data = [];

class survey extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDeleteSurvey = this.handleDeleteSurvey.bind(this);
    this.handleEditSurvey = this.handleEditSurvey.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      setTickets: [],
      surveyId: "",
      title: "",
      question: "",
      country: "",
      status: "",
      showDeleteMessage: false
    };
  }

  getAllSurveys = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/surveymasters`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      this.setState({ setTickets: response.data });
      data = response.data;
      // console.log(data);
      this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  handleEditSurvey = (surveyId, title, question, country, status, event) => {
    event.preventDefault();

    this.setState({ id: surveyId });
    this.setState({ title: title });
    this.setState({ question: question });
    this.setState({ country: country });
    this.setState({ status: status });
    this.setState({ redirect: true });
    this.setState({ directTo: "/surveyEdit" })
  }

  handleDeleteSurvey = (surveyId, event) => {
    event.preventDefault();
    this.setState({ id: surveyId });
    this.setState({ showDeleteMessage: true });
  }

  handleDeleteSurvey() {
    this.deleteSurvey();
  }

  deleteSurvey = async () => {
    try {
      const id = this.state.id;

      await axios.delete(`${config.api.invokeUrl}/surveymasters/` + id, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      this.getAllSurveys();
      this.setState({ id: "" });
      this.setState({ showDeleteMessage: false });
    } catch (err) {
      console.log("error");
    }
  }

  componentDidMount() {
    this.getAllSurveys();

    if (this.state.userRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }
  }

  handleClose() {
    this.setState({ showDeleteMessage: false });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          surveyId: this.state.id,
          title: this.state.title,
          country: this.state.country,
          question: this.state.question,
          status: this.state.status
        }
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
              <tbody>
                <tr>
                  <td><span className="pageTitle">Survey Administration</span></td>
                  <td style={{ textAlign: "right" }}><Link to={`/surveyNew/`}><button style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Create Survey | <img src={plus}></img></button></Link></td>
                </tr>
              </tbody>
            </table>
            <br />
            <div style={{ display: this.state.errMsg_region }}>
              <SurveyComponent
                handleDeleteSurvey={this.handleDeleteSurvey}
                handleEditSurvey={this.handleEditSurvey}
                tickets={data}
              />
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDeleteMessage}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Delete Survey</span>
            </p>
            <p>
              <span>Are you sure you want to delete this survey?</span>
            </p>
            <p>
              <button className="btn btn-secondary" onClick={this.handleDeleteSurvey} style={{ margin: '0px 10px 0px 0px' }} >
                Yes
              </button>
              <button className="btn btn-danger" onClick={this.handleClose} >
                No
              </button>
            </p>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default survey;