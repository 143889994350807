import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";


const config = require("../config.json");

// let Obj = "";
let sCountry = "";

class regionedit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: this.props.location.state.isAdminRole,
      redirect: false,
      redirectTo: "/",
      regionId: this.props.location.state.regionId,
      regionname: this.props.location.state.regionname,
      countryId: this.props.location.state.country,
      // sCountry: "",
      countryOptions: [],
      errMsg_country: "none",
      errMsg_region: "none",
      errMsg_name: "none"
    };
  }

  onNameChange = event => this.setState({ regionname: event.target.value });

  onCountryChange = event => {
    sCountry = event.value;
    let obj = { value: event.value, label: event.label };
    this.setState({ countryId: obj }, () => {
      // Do an API call with this.state.searchTerm

    });
    // console.log(obj);
    // console.log(sCountry);
  }

  handleUpdateRegion = e => {
    e.preventDefault();

    let formValid = true;

    if (this.state.regionname === '') {
      this.setState({ errMsg_name: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_name: "none" });
    }

    // if(this.state.region === '') {     
    //     this.setState({errMsg_region: "block"});    
    //     formValid = false;                
    // }else{            
    //     this.setState({errMsg_region: "none"});
    // } 

    if (formValid === true) {
      console.log('form is valid: submit');
      this.updateRegion();
    }
  }

  componentDidMount() {
    this.getAllCountries();
    let Obj = { value: this.state.countryId, label: this.removeUnderscore(this.state.countryId) };
    this.setState({ countryId: Obj });
    sCountry = this.props.location.state.country;
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  getAllCountries = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if ((res.data.length > 0) && (res.status = "200")) {
        const countrydata = res.data;
        const options = countrydata.map(d => ({
          "value": d.Id,
          "label": d.countryname
        }))

        this.setState({ countryOptions: options })

      } else {
        localStorage.clear();
        console.log("error");
      }

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  updateRegion = async () => {
    try {

      const params = {
        "Id": this.state.regionId,
        "regionname": this.state.regionname,
        "country": sCountry
      };

      // console.log(params);

      await axios.patch(`${config.api.invokeUrl}/region/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ redirectTo: "/regionAdmin" });
      this.setState({ redirect: true });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Edit Region</h5>
            <br></br>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>

              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={this.handleUpdateRegion}
                noValidate>
                <div className="container">
                  {this.state.isAdminRole && (
                    <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                      <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                      <div style={{ width: "350px" }}>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={this.state.countryDisabled}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          placeholder="Select country "
                          onChange={this.onCountryChange}
                          required
                          styles={style}
                          options={this.state.countryOptions}
                          // value={{ value: this.state.countryId, label: this.removeUnderscore(this.state.countryId) }}
                          value={this.state.countryId}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        >
                        </Select>
                        <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                      </div>
                    </div>
                  )}

                  <div className="form-row">
                    <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Region Name"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.regionname}
                    />
                  </div>

                  <div className="form-row">
                    <span style={{ margin: '0px 0px 0px 40px', display: this.state.errMsg_name, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>

                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/regionAdmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>
                </div>
              </FormWithConstraints>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default regionedit;