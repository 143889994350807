import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise.png";
import logout from "../log-out white.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require("../config.json");
const cookies = new Cookies();

class countryNew extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/",
      name: "",
      errMsg_region: "none",
      errMsg_name: "none",
      selectedFile: null
    };
  }

  onNameChange = event => this.setState({ name: event.target.value });

  // onFileChange = event => {
  //   this.setState({ selectedFile: event.target.files[0] });
  // }

  // onFileUpload = async () => {
    
  //   const formData = new FormData();
  
  //   formData.append(
  //     "myFile",
  //     this.state.selectedFile,
  //     this.state.selectedFile.name
  //   );
  
  //   console.log(this.state.selectedFile);

  //   // axios.post("api/uploadfile", formData);

  //   try {

  //     await axios.post(`${config.api.countryUrl}/country/{id}`, formData, {
  //       "headers": {
  //         "x-api-key": `${config.api.xapikey}`
  //       }
  //     });

  //     this.setState({ redirectTo: "/countryAdmin" });
  //     this.setState({ redirect: true });

  //   } catch (err) {
  //     console.log(`An error has occurred: ${err}`);
  //   }

  // };

  // updateInputValue(evt) {
  //   this.setState({
  //     inputValue: evt.target.value,
  //   });
  // }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g,"_");
    return string;
  }

  componentDidMount() {
    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }
  }

  handleAddCountry = async (id, event) => {
    event.preventDefault();
    let formValid = true;

    if (this.state.name === '') {
      this.setState({ errMsg_name: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_name: "none" });
    }

    if (formValid === true) {
      // add call to AWS API Gateway add product endpoint here 
      const guid = uuidv4();

      try {
        const params = {
          "id": this.removeWhiteSpace(this.state.name),
          "name": this.state.name
        };

        await axios.post(`${config.api.invokeUrl}/country/{id}`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });

        this.setState({ redirectTo: "/countryAdmin" });
        this.setState({ redirect: true });

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    }
  }

  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton" ><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Add New Country</h5>
            <br></br>

            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>

              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={event => this.handleAddCountry(this.state.name, event)}
                noValidate>

                <div className="container">
                   <div className="form-row">
                    <img src={globe} style={{ margin: '0px 5px 0px 0px' }} />
                    <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Country Name"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                    />
                  </div>
                  <div className="form-row">
                    <span style={{ margin: '0px 0px 0px 40px', display: this.state.errMsg_name, color: 'red' }}>Please complete.</span>
                  </div>

                  {/* <div className="form-row">
                    <input type="file" accept="image/*" onChange={this.onFileChange} />
                  </div> */}

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/countryAdmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>
                </div>
              </FormWithConstraints>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default countryNew;