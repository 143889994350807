import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import { Link } from "react-router-dom";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import Select from "react-select";
import { Redirect } from 'react-router';
import plus from "../plus.png";
import RegionComponent from "./regionComponent";
// import CountryList from "./country-list";
import Modal from "react-bootstrap/Modal";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const config = require('../config.json');
const cookies = new Cookies();
let data = [];
let sCountry = "";
let sRegion = "";

class regions extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDeleteRegion = this.handleDeleteRegion.bind(this);
    this.handleEditRegion = this.handleEditRegion.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleBuildingClose = this.handleBuildingClose.bind(this);

    this.state = {
      isAdminRole: false,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      countryDisabled: false,
      redirect: false,
      redirectTo: "/",
      errMsg_region: "none",
      showBuildingMessage: false,
      showDeleteMessage: false,
      countryOptions: [],
      countryId: "",
      regionname: "",
      deleteId: "",
      editId: ""
    };
  }

  handleClose() {
    this.setState({ showDeleteMessage: false });
  }

  handleBuildingClose() {
    this.setState({ showBuildingMessage: false });
  }

  handleDeleteRegion = (regionId, event) => {
    event.preventDefault();
    sRegion = regionId;

    this.setState({ deleteId: regionId });

    this.checkBuildingExist();

    // this.setState({ showDeleteMessage: true });
  }

  deleteRegion = async () => {
    try {
      const id = this.state.deleteId; //.toLowerCase();

      // console.log(id);
      await axios.delete(`${config.api.invokeUrl}/region/` + id, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      toast.success('Region successfully deleted');

      this.getAllRegions();
      this.setState({ deleteId: "" });
      this.setState({ showDeleteMessage: false });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  checkBuildingExist = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/buildings/` + sRegion, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      // this.setState({ setTickets: response.data });
      // data = response.data;

      if (response.data.length === 0) {
        this.setState({ showDeleteMessage: true });
      } else {
        this.setState({ showBuildingMessage: true });
      }

      // this.setState({ errMsg_building: "block" });

    } catch (err) {
      console.log("error");
    }
  };

  handleEditRegion = (regionId, regionname, country, event) => {
    event.preventDefault();
    // console.log('id :'+ regionId + 'name :' + regionname);
    this.setState({ editId: regionId });
    this.setState({ regionname: regionname });
    this.setState({ countryId: country });
    // console.log(this.state.countryId);
    this.setState({ redirect: true });
    this.setState({ redirectTo: "/regionEdit" })
  }

  componentDidMount() {
    this.getAllCountries();

    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {
      this.setState({ isAdminRole: true });
    }
    else {
      // this.getAllCountries();
      // this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });

      sCountry = this.removeWhiteSpace(this.state.adminCountry);
      this.setState({ countryDisabled: true });

      // console.log(this.state.adminCountry);
      console.log(sCountry);
      // console.log(this.state.countryId);

      this.setState({ region: "" });
      this.getAllRegions();
    }

    // if (this.state.userRole !== "Administrator") {
    //   this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });
    //   sCountry = this.removeWhiteSpace(this.state.adminCountry);
    //   this.setState({ countryDisabled: true });
    //   console.log(sCountry);
    //   this.getAllRegions();
    // }
  }

  getAllCountries = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if ((res.data.length > 0) && (res.status = "200")) {
        const countrydata = res.data;
        const options = countrydata.map(d => ({
          "value": d.Id,
          "label": d.countryname
        }))

        this.setState({ countryOptions: options })

      } else {
        localStorage.clear();
        console.log("error");
      }

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  getAllRegions = async () => {
    try {
      //console.log(sCountry);
      if (sCountry !== '') {
        const response = await axios.get(`${config.api.invokeUrl}/region/?id=` + sCountry, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
        // console.log(country);
        console.log(response.data);
        this.setState({ setTickets: response.data });
        data = response.data;
        this.setState({ errMsg_region: "block" });
      }
    } catch (err) {
      console.log("Error:" + err);
    }
  };

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  onCountryChange = event => {
    // this.setState({ countryId: event.value });
    let obj = { value: event.value, label: event.label };
    this.setState({ countryId: obj }, () => {
      this.getAllRegions();
    });
    sCountry = event.value;

  }

  render() {

    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo,
        state: {
          regionId: this.state.editId,
          regionname: this.state.regionname,
          country: this.state.countryId,
          isAdminRole: this.state.isAdminRole
        }
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button>
          <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
            <tbody>
              <tr>
                <td><span className="pageTitle">Region Administration</span></td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}><Link to={`/regionNew/`}><button style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Add Region | <img src={plus}></img></button></Link></td>
              </tr>
              <tr>
                <td>
                  <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{ margin: '10px 0px 0px 0px' }}>
            <div className="container">
              {this.state.isAdminRole && (
                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                  <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                  <div style={{ width: "350px" }}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={this.state.countryDisabled}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      placeholder="Select country "
                      onChange={this.onCountryChange}
                      required
                      styles={style}
                      options={this.state.countryOptions}
                      // value={{ value: this.state.countryId, label: this.removeUnderscore(this.state.countryId)}}
                      value={this.state.countryId}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    >
                    </Select>
                  </div>
                </div>
              )}
            </div>

            <div style={{ display: this.state.errMsg_region, margin: '20px 0px 0px 0px' }}>
              <RegionComponent onCreate={() => this.getAllRegions()}
                handleDeleteRegion={this.handleDeleteRegion}
                handleEditRegion={this.handleEditRegion}
                tickets={data}
              />
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDeleteMessage}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Delete Region</span>
            </p>
            <p>
              <span>Are you sure you want to delete this region?</span>
            </p>
            <p>
              <button className="btn btn-secondary" onClick={this.deleteRegion} style={{ margin: '0px 10px 0px 0px' }} >
                Yes
              </button>
              <button className="btn btn-danger" onClick={this.handleClose} >
                No
              </button>
            </p>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showBuildingMessage}
          onHide={this.handleBuildingClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Cannot delete Region</span>
            </p>
            <p>
              <span>There are Buildings attached to this region!</span>
            </p>
            <p>
              <button className="btn btn-danger" onClick={this.handleBuildingClose} >
                Close
              </button>
            </p>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default regions;