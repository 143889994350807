import React, { Component } from 'react';
import { Redirect } from 'react-router';
import "./logon.css";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprisew from "../enterprise white.png";
import enterprise from "../enterprise.png";
import logout from "../log-out white.png";
import network from "../user_rank-networker.png";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import region from "../location_white.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { v4 as uuidv4 } from "uuid";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";

const config = require("../config.json");
const cookies = new Cookies();
let sCountry = "";
let sRegion = "";

class buildingedit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdminRole: this.props.location.state.isAdminRole,
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      redirect: false,
      redirectTo: "/",
      id: this.props.location.state.buildingId,
      regionId: this.props.location.state.regionId,
      buildingName: this.props.location.state.buildingName,
      regionName: this.props.location.state.regionName,
      errMsg_region: "none",
      errMsg_name: "none",
      countryId: this.props.location.state.country,
      regionOptions: []
    };
  }

  onNameChange = event => this.setState({ buildingName: event.target.value });

  onRegionChange = event => {
    // this.setState({ regionId: event.value });
    this.setState({ errMsg_region: "none" });

    sRegion = event.value;
    // console.log(sRegion);

    let obj = {value: event.value, label: event.label};
    this.setState({ regionId: obj }, ()=> {  
    });
  }

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g,"_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g," ");
    return string;
  }

  componentDidMount() {

    sCountry = this.state.countryId;
    sRegion = this.state.regionName

    // console.log(sRegion);

    this.getAllRegions();

    // if (this.state.userRole !== "Administrator") {
    //   this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });
    //   sCountry = this.removeWhiteSpace(this.state.adminCountry);
    //   this.setState({ countryDisabled: true });
    //   console.log(sCountry);
    //   this.getAllRegions();
    //   console.log(this.state.buildingName);
    //   console.log(this.state.regionId);

    // }
  }

  getAllRegions = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/region/` + sCountry, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);
      const regiondata = response.data;

      const options = regiondata.map(d => ({
        "value": d.Id,
        "label": d.regionname
      }))

      this.setState({ regionOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  }


  handleUpdateBuilding = e => {
    e.preventDefault();
    let formValid = true;

    if (this.state.buildingName === '') {
      this.setState({ errMsg_name: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_name: "none" });
    }

    if (this.state.regionId === '') {
      this.setState({ errMsg_region: "block" });
      formValid = false;
    } else {
      this.setState({ errMsg_region: "none" });
    }

    if (formValid === true) {
      console.log('form is valid: submit');
      this.updateBuilding();
    }
  }

  updateBuilding = async () => {
    try {

      const params = {
        "Id": this.state.id,
        "buildingname": this.state.buildingName,
        "buildingregion": sRegion
      };

      // console.log(params);

      await axios.patch(`${config.api.invokeUrl}/buildings/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ redirectTo: "/buildingAdmin" });
      this.setState({ redirect: true });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }



  render() {
    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={enterprisew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          <div style={{ margin: '0px 0px 0px 10px' }}>
            <h5 style={{ margin: '15px 0px 0px 0px', fontWeight: "bold" }}>Edit Building</h5>
            <br></br>
            <div style={{ display: this.state.showFields, margin: '10px 0px 0px 0px' }}>

              <FormWithConstraints
                ref={form => this.form = form}
                onSubmit={this.handleUpdateBuilding}
                noValidate>

                <div className="container">
                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>

                    <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />

                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.regionDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={this.state.regionOptions}
                        placeholder="Select region "
                        onChange={this.onRegionChange}
                        required
                        styles={style}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        // value={{ value: this.state.regionId, label: this.removeUnderscore(this.state.regionId) }}
                        value={this.state.regionId}
                      /> <br />
                      <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_region, color: 'red' }}>Please complete.</span>
                    </div>
                  </div>
                  
                  <div className="form-row">
                    {this.props.buildingName}
                    <img src={enterprise} style={{ margin: '0px 5px 0px 0px' }} />

                    <input
                      name="buildingName"
                      id="buildingName"
                      type="text"
                      placeholder="Building Name"
                      className="form-control+matching-text-style"
                      onChange={this.onNameChange}
                      style={{ width: '350px', color: "#4A4D4E" }}
                      value={this.state.buildingName}
                    />
                  </div>

                  <div className="form-row">
                    <span style={{ margin: '0px 0px 0px 40px', display: this.state.errMsg_name, color: 'red' }}>Please complete.</span>
                  </div>

                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>

                    <button style={{ margin: '20px 5px 0px 0px' }} className="btn btn-info" >Save</button>
                    <Link to={`/buildingAdmin/`}><button style={{ margin: '20px 0px 0px 0px' }} className="btn btn-danger" >Cancel</button> </Link>
                  </div>
                </div>

              </FormWithConstraints>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default buildingedit;