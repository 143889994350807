import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import logout from "../log-out white.png";
import { Link } from "react-router-dom";
import tracker from "../user_tracker.png";
import globew from "../globe_white.png";
import globe from "../globe.png";
import region from "../location_white.png";
import Select from "react-select";
// import AsyncSelect from 'react-select/async';
import { Redirect } from 'react-router';
import plus from "../plus.png";
import BuildingComponent from "./buildingComponent";
import Modal from "react-bootstrap/Modal";
import chart from "../chart-bar white.png";
import rejectwhite from "../reject white.png";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const config = require('../config.json');
const cookies = new Cookies();
let data = [];
let sRegion = "";
let sCountry = "";
let adminDisplay = false;

// const regionoptions = [
//   { value: "North", label: "North Region" },
//   { value: "South", label: "South Region" },
// ];

class buildings extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleDeleteBuilding = this.handleDeleteBuilding.bind(this);
    this.handleEditBuilding = this.handleEditBuilding.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // const regionOptions = [];

    this.state = {
      isAdminRole: false,
      // adminDisplay: 'block',
      userRole: cookies.get('DVAdminRole'),
      adminCountry: cookies.get('DVAdminCountry'),
      countryDisabled: false,
      redirect: false,
      redirectTo: "/",
      errMsg_country: "none",
      errMsg_region: "none",
      errMsg_building: "none",
      showDeleteMessage: false,
      countryId: "",
      countryName: "",
      adminRegion: "",
      regionId: "",
      regionName: "",
      deleteId: "",
      editId: "",
      countryOptions: [],
      regionOptions: [],
      selectedRegion: ""
      // select: {
      //   value: regionOptions[0], // "One" as initial value for react-select
      //   regionOptions // all available options
      // }
    };
  }

  handleClose() {
    this.setState({ showDeleteMessage: false });
  }

  handleDeleteBuilding = (buildingId, event) => {
    event.preventDefault();
    //alert(id);
    // console.log(buildingId);
    this.setState({ deleteId: buildingId });
    this.setState({ showDeleteMessage: true });
    // this.deleteBuilding();
  }

  // handleDeleteBuilding() {
  //   // alert(this.state.deleteId);

  // }
  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g, "_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  deleteBuilding = async () => {
    try {
      const id = this.state.deleteId; //.toLowerCase();

      // console.log(id);

      await axios.delete(`${config.api.invokeUrl}/buildings/` + id, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ deleteId: "" });
      this.setState({ showDeleteMessage: false });

      this.getAllBuildings();

      toast.success('Building successfully deleted');

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleEditBuilding = (buildingId, name, event) => {
    event.preventDefault();
    this.setState({ editId: buildingId });
    this.setState({ name: name });
    this.setState({ redirect: true });
    this.setState({ redirectTo: "/buildingEdit" })
  }

  componentDidMount() {
    this.getAllCountries()

    const sUserRole = this.state.userRole;
    if (sUserRole === "Administrator") {
      this.setState({ isAdminRole: true });
      // adminDisplay = true;
    } else {
      this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });
      sCountry = this.removeWhiteSpace(this.state.adminCountry);
      this.setState({ countryDisabled: true });
      console.log(sCountry);
      this.getAllRegions();
    }

    // if (this.state.userRole !== "Administrator") {

    // }
  }

  getAllCountries = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/country`, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(response.data);

      // this.setState({ countryOptions: response.data });

      const countrydata = response.data;
      const options = countrydata.map(d => ({
        "value": d.Id,
        "label": d.countryname
      }))

      this.setState({ countryOptions: options })

      // this.setState({ errMsg_country: "block" });
    } catch (err) {
      console.log("error");
    }
  };

  getAllRegions = async () => {
    try {
      //console.log(sCountry);
      const response = await axios.get(`${config.api.invokeUrl}/region/?id=` + sCountry, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      console.log(response.data);

      this.setState({ adminRegion: response.data})

      const regiondata = response.data;

      const options = regiondata.map(d => ({
        "value": d.Id,
        "label": d.regionname
      }))

      this.setState({ regionOptions: options })

      // this.setState({ errMsg_region: "block" });
    } catch (err) {
      console.log("error");
    }
  }

  getAllBuildings = async () => {

    let myregionname = this.state.adminRegion[0].regionname;

    // console.log(myregionname);

    try {
      // console.log(sRegion);
      // const response = await axios.get(`${config.api.invokeUrl}/buildings/?id=` + sRegion, {
      const response = await axios.get(`${config.api.invokeUrl}/buildings/{id}` + myregionname, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      
      // this.setState({ setTickets: response.data });
      data = response.data;
      
      console.log(response.data);

      this.setState({ errMsg_building: "block" });

    } catch (err) {
      console.log("error");
    }
  };

  // onCountryChange = event => {
  //   this.setState({ country: event.value });
  //   sCountry = event.value;
  //   this.getAllRegions();
  // }

  // onAddVisitorRegionChange = event => {
  //   this.setState({ region: event.value });
  //   sRegion = event.value;
  //   this.getAllBuildings();
  // }

  onCountryChange = event => {

    // this.setState({ countryId: event.value });
    // this.setState({ setSelectedCountryValue: event.value });
    // this.setState({ country: e.value });
    sCountry = event.value;
    this.setState({ countryName: event.value })
    let obj = { value: event.value, label: event.label };
    this.setState({ countryId: obj }, () => {
      // Do an API call with this.state.searchTerm

    });

    // console.log(this.state.countryId);


    // console.log('oncountrychange : '+ event.value);
    // this.setState({ region: "" });
    // this.setState({ regionOptions: [] })
    // this.setState({ selectedRegion: null })
    this.getAllRegions();
  }

  onRegionChange = event => {
    // this.setState({ region: e.value });
    // this.setState({ regionId: e.value, regionName: e.label})

    this.setState({ errMsg_region: "none" });

    sRegion = event.value;

    let obj = { value: event.value, label: event.label };
    this.setState({ regionId: obj });   //  convert to obj

    // this.setState({ regionId: event.value || null })

    // this.setState({ selectedRegion: this.state.regionOptions[0] })

    this.getAllBuildings();
  }

  render() {

    const { select } = this.state;

    const style = {
      control: (base, state) => ({
        ...base,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #707070',
        boxShadow: 'none',
        '&:hover': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid #707070',
        }
      })
    };

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirectTo,
        state: {
          buildingId: this.state.editId,
          country: this.state.countryName,
          regionId: this.state.regionId,
          regionName: sRegion,
          buildingName: this.state.name,
          isAdminRole: this.state.isAdminRole
        }
      }} />;
    }
    return (
      <div>
        <div className="top">
          <Header></Header>
        </div>
        <div className="left">
          <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
          <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
          <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
          <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
          <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
          <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
          <button className="menubutton" style={{ backgroundColor: '#7FA5D0' }}><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button>
          <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
          <Link to={`/report/`}><button className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
          <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
        </div>
        <div className="main">
          {/* <div style={{ margin: '0px 0px 0px 10px' }}> */}
            <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
              <tbody>
                <tr>
                  <td><span className="pageTitle">Building Administration</span></td>
                  <td style={{ textAlign: "right", verticalAlign: "middle" }}><Link to={`/buildingNew/`}><button style={{ margin: '0px 0px 0px 10px' }} className="btn btn-danger">Add Building | <img src={plus}></img></button></Link></td>
                </tr>
                <tr>
                  <td>
                    <ToastContainer
                      position="bottom-left"
                      autoClose={3000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable={false}
                      pauseOnHover
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ margin: '10px 0px 0px 0px' }}>
              <div className="container">
                {this.state.isAdminRole && (
                  <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                    <img src={globe} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                    <div style={{ width: "350px" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={this.state.countryDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        placeholder="Select country "
                        onChange={this.onCountryChange}
                        required
                        styles={style}
                        options={this.state.countryOptions}
                        // value={{ value: this.state.countryId, label: this.removeUnderscore(this.state.countryId)}}
                        value={this.state.countryId}
                        components={{
                          IndicatorSeparator: () => null
                        }}

                      // {this.state.countryOptions.map((e, key) => {
                      //   return <option key={key} value={e.Id}>{e.countryname}</option>;
                      // })}
                      >
                      </Select>

                    </div>
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_country, color: 'red' }}>Please complete.</span>
                  </div>
                )}

                <div className="form-row" style={{ padding: '10px 0px 0px 0px' }}>
                  <img src={tracker} style={{ margin: '10px 5px 0px 0px', width: "32px", height: "32px" }} />
                  <div style={{ width: "350px" }}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={this.state.regionDisabled}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      placeholder="Select region "
                      onChange={this.onRegionChange}
                      //   onChange={e => {
                      //     this.setState({
                      //       selectedRegion: e.label,
                      //       selectedRegion: e.value
                      //     });
                      //     sRegion: e.value;
                      //     this.getAllBuildings();
                      //  }}
                      required
                      styles={style}
                      options={this.state.regionOptions}
                      // key={`my_unique_select_key__${this.state.selectedRegion}`}
                      // value={select.value}
                      defaultValue={{ label: "Select region ", value: 0 }}
                      // value={{ value: this.state.selectedRegion, label : this.removeUnderscore(this.state.selectedRegion) }}
                      value={this.state.regionId}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    >
                    </Select>
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_region, color: 'red' }}>Please complete.</span>
                  </div>
                </div>
                <div style={{ display: this.state.errMsg_building, margin: '20px 0px 0px 0px' }}>
                  <BuildingComponent onCreate={() => this.getAllCountries()}
                    handleDeleteBuilding={this.handleDeleteBuilding}
                    handleEditBuilding={this.handleEditBuilding}
                    tickets={data}
                  />
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>


        <Modal
          show={this.state.showDeleteMessage}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>

            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Delete Building</span>
            </p>
            <p>
              <span>Are you sure you want to delete this building?</span>
            </p>
            <p>
              <button className="btn btn-secondary" onClick={this.deleteBuilding} style={{ margin: '0px 10px 0px 0px' }} >
                Yes
              </button>
              <button className="btn btn-danger" onClick={this.handleClose} >
                No
              </button>
            </p>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default buildings;