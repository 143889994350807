import React from "react";
import { Link } from "react-router-dom";



const TicketsComponent = ({ tickets }) => {

  
// a function that assigns bootstrap styling classes based on 
// the status of the ticket
  const assignColorToTicketStatus = ticket => {
    if (ticket.status === "completed") {
      return "p-3 mb-2 bg-success text-white";
    } else if (ticket.status === "in_progress") {
      return "p-3 mb-2 bg-warning text-dark";
    } else if (ticket.status === "opened") {
      return "p-3 mb-2 bg-light text-dark";
    }
  };



   return (
    <div className="container" style={{height: "700px", overflowY: "scroll"}}>
      {tickets.length === 0 ? (
        "Loading administrator users."
      ) : (
        <table className="table" style={{ margin: '0px 0px 50px 0px'  }}>
          <thead style={{backgroundColor: '#4A4D4E', color: 'white'}}>
            <tr>              
              <th scope="col">Fisrt Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>                        
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {tickets.map(ticket => (
              <tr key={ticket.id}>
                <td>{ticket.name}</td>             
                <td>{ticket.lastname}</td>             
                <td>{ticket.id}</td>             
                <td>{ticket.role}</td>                           
                <td style={{width: '220px'}}>
                  <Link to={`/adminUserEdit/${ticket.id}`}><button style={{margin: '0px 0px 0px 0px'}} className="btn btn-secondary">Edit</button></Link>                                  
                  <button style={{margin: '0px 0px 0px 10px'}} className="btn btn-danger">Delete</button>              
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TicketsComponent;