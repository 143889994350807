import React, { Component } from 'react';
import prssaLogo from "../pernod_white.png"
import profilePic from "../profilePic.png";
import "./header.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const getFlagEmoji = countryCode=>String.fromCodePoint(...[...countryCode.toUpperCase()].map(x=>0x1f1a5+x.charCodeAt(0)))

class Header extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            adminUsername: cookies.get('DVAdminMail'),
            countryName: cookies.get('DVAdminCountry'),
            countryFlag: ""
        };
    }

    removeUnderscore(stringfromdb) {
        var string = stringfromdb;
        string = string.replace(/_/g," ");
        return string;
    }

    componentDidMount() {
        // const url = 'https://countryflagsapi.com/png/';
        // const sCountry = this.removeUnderscore(this.state.countryName);
        // this.setState({ countryFlag: url + sCountry });

        // removeUnderscore(this.state.countryName);
    }
    
    render() { 
        return (
            <div className="d-flex justify-content-between" style={{backgroundColor: "#023466", height: "150px"}}>
                <div><img style={{ margin: '10px 0px 0px 10px' }} src={prssaLogo}></img></div>
                <div className="headerTitleText">Visitor Management System Admin Portal</div>
                <div>
                    <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
                        <tbody>
                            <tr>
                                <td style={{textAlign: "right", verticalAlign: "middle", fontSize: "12px", nowrap: "nowrap", right: "15px"}}>
                                    <span style={{color: "white"}}>{this.state.adminUsername}</span>
                                    <br />
                                    <span style={{color: "white"}}><b>{cookies.get('DVAdminRole')}</b></span>
                                    <br />
                                    <span style={{color: "white", fontSize: "15px"}}><img src="https://flagcdn.com/16x12/za.png" style={{height: "12px", width: "16px"}} alt="" />     <b>{this.removeUnderscore(this.state.countryName)}</b></span>
                                    {/* <span style={{color: "white", fontSize: "15px"}}><img src={this.state.countryFlag} style={{height: "17px", width: "27px"}} alt="" />     <b>{this.state.countryName}</b></span> */}                                
                                </td>
                                {
                                    cookies.get('DVAdminPhoto') === ''
                                    ?<td style={{textAlign: "right", verticalAlign: "middle"}}><img className="img-circularx" src={profilePic} /> </td>
                                    :<td style={{textAlign: "right", verticalAlign: "middle"}}><img className="img-circularx" src={`data:image/jpeg;base64,${cookies.get('DVAdminPhoto')}`} /> </td>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            



            // <div style={{backgroundColor: "#023466", padding: "0px", height: "150px"}}>
            //     <table className="table" style={{ margin: '0px 0px 0px 0px' }}>
            //         <tbody>
            //             <tr>

            //                 <td style={{textAlign: "left"}}><img src={prssaLogo}></img></td>
            //                 <td style={{textAlign: "center", verticalAlign: "middle"}}><span className="headerTitleText">Visitor Management System Admin Portal</span></td>
            //                 <td style={{textAlign: "right", verticalAlign: "middle", fontSize: "12px", nowrap: "nowrap", right: "15px"}}>
            //                     <span style={{color: "white"}}>{this.state.adminUsername}</span>
            //                     <br />
            //                     <span style={{color: "white"}}><b>{cookies.get('DVAdminRole')}</b></span>
            //                     <br />
            //                     <span style={{color: "white", fontSize: "15px"}}><img src="https://flagcdn.com/16x12/za.png" style={{height: "12px", width: "16px"}} alt="" />     <b>{this.removeUnderscore(this.state.countryName)}</b></span>
            //                     {/* <span style={{color: "white", fontSize: "15px"}}><img src={this.state.countryFlag} style={{height: "17px", width: "27px"}} alt="" />     <b>{this.state.countryName}</b></span> */}                                
            //                 </td>
            //                 {
            //                     cookies.get('DVAdminPhoto') === ''
            //                     ?<td style={{textAlign: "right", verticalAlign: "middle"}}><img className="img-circularx" src={profilePic} /> </td>
            //                     :<td style={{textAlign: "right", verticalAlign: "middle"}}><img className="img-circularx" src={`data:image/jpeg;base64,${cookies.get('DVAdminPhoto')}`} /> </td>
            //                 }                             
            //             </tr>
            //         </tbody>
            //     </table>                    
            // </div>                  
        );
    }
}
 
export default Header;


