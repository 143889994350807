import React, { Component, useRef, useEffect, useState } from 'react';
import axios from "axios";
import Header from './header';
import admin from "../admin-white.png";
import chartline from "../chart-line white.png";
import enterprise from "../enterprise white.png";
import globew from "../globe_white.png";
// import globe from "../globe.png";
import region from "../location_white.png";
import logout from "../log-out white.png";
import { Link } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
import { Redirect } from 'react-router';
// import plus from "../plus.png";
// import ReportComponent from "./reportComponent";
import chart from "../chart-bar white.png";
import Cookies from "universal-cookie";
// import Papa from 'papaparse';

import rejectwhite from "../reject white.png";
import PaginationTableComponent from './pagination.table';
import * as XLSX from 'xlsx';
import LoadingSpinner from './loadingSpinner';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "./report.css";

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from 'react-date-range';
// import HttpClient from "./HttpClient";
// import httpClient from 'react-http-client';
import { Table, Row, Col } from 'react-bootstrap';
// import Grid from 'react-bootstrap/lib/Grid';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from './errorBoundary';

import LogRocket from 'logrocket';
LogRocket.init('thbowa/vmsadminprod');


console.clear();
const cookies = new Cookies();
const config = require('../config.json');
const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let data = [];
let sCountry = "";

// let tmpDate = new Date();
// let startDate = new Date(tmpDate.setMonth(tmpDate.getMonth()-1));
// let endDate = new Date();

// const dataInCSV = "";
// const csvData = "";
let startTimeStamp = " ";
let endTimeStamp = " ";

class report extends Component {
    // const report = () =>  {
    // const tableRef = useRef(null);

    constructor(props, context) {
        super(props, context);

        this.handleFilterReport = this.handleFilterReport.bind(this);

        this.state = {
            isAdminRole: false,
            redirect: false,
            filter: "",
            countryId: "",
            errMsg_country: "none",
            setTickets: [],
            selectOptions: [],
            countryDisabled: false,
            userRole: cookies.get('DVAdminRole'),
            adminCountry: cookies.get('DVAdminCountry'),
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getUTCFullYear(),
            dataInCSV: "",
            startDate: "",
            endDate: "",
            setStartTimestamp: "",
            setEndTimestamp: "",
            isLoading: false
        };
    }

    handleFilterReport = (filter, event) => {
        event.preventDefault();
        this.setState({ filter: filter });
        if (filter === "") {
            this.getAllSurveys();
        } else {
            this.getAllVisitsFilter(filter);
        }
    }

    // // Function to export data as a file
    // exportData2 = (e) => {

    //     console.log('test export');
    //     return;


    // };

    // // Function to export data as a file
    // exportData = (data, fileName, type) => {

    //     // Create a link and download the file
    //     const blob = new Blob([data], { type });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = fileName;
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    // };

    downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    };

    onCountryChange = event => {

        sCountry = event.value;
        this.setState({ errMsg_country: "none" });

        let obj = { value: event.value, label: event.label };
        this.setState({ countryId: obj }, () => {

        });

        // console.log(sCountry);
        // console.log(this.state.countryId);
        this.getAllSurveys();
    }

    removeWhiteSpace(stringfordb) {
        var string = stringfordb;
        string = string.replace(/ /g, "_");
        return string;
    }

    removeUnderscore(stringfromdb) {
        var string = stringfromdb;
        string = string.replace(/_/g, " ");
        return string;
    }

    // handleDatesSelect(ranges){
    //     console.log(ranges);
    //     {
    //       selection: {
    //         startDate: console.log(ranges);
    //         endDate: console.log(ranges);
    //       }
    //     }
    // }

    setStartDate(value, e) {
        // this.setState({ startDate: date });

        // console.log(value);
        console.log(value.toISOString());

        // console.log(Date.parse(value).toString());

        // let currentTimeInMilliseconds = " ";
        startTimeStamp = Date.parse(value).toString();
        // console.log(currentTimeInMilliseconds);

        this.setState({ setStartTimestamp: startTimeStamp })

        // const test = value.toTimeString();
        // console.log(test);
        // console.log(e); // this will be a string value in datepicker input field
        this.setState({ startDate: value });
        // console.log(this.state.startDate);

        this.getAllSurveys();
    }

    setEndDate(value, e) {
        // this.setState({ endDate: date });
        // let endTimeStamp = " ";
        endTimeStamp = Date.parse(value).toString();
        // console.log(currentTimeInMilliseconds);

        this.setState({ setEndTimestamp: endTimeStamp })



        this.setState({ endDate: value });

        this.getAllSurveys();

    }

    // handleStartDateSelect (date) {
    //     // this.setState({ startDate: date });
    //     console.log(date);
    //     // startDate = date;
    // }

    // handleEndDateSelect (date) {
    //     // this.setState({ startDate: date });
    //     console.log(date);
    //     // startDate = date;
    // }

    getAllCountries = async () => {
        try {
            const response = await axios.get(`${config.api.invokeUrl}/country`, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });
            // console.log(response.data);
            const regiondata = response.data;

            const options = regiondata.map(d => ({
                "value": d.Id,
                "label": d.countryname
            }))

            // console.log(options);

            this.setState({ selectOptions: options })

            // this.setState({ errMsg_region: "block" });
        } catch (err) {
            console.log("error");
        }
    };

    getAllSurveys = async () => {

        this.setState({ isLoading: true });
        const params = {
            // "ym": this.state.currentYear.toString() + (this.state.currentMonth + 1).toString(),
            "startdate": startTimeStamp,
            "enddate": endTimeStamp
            // "country": sCountry,
            // "country": "South_Africa",
            // "id": localStorage.getItem('username'),
            // "usid": localStorage.getItem('usersession')
        };
        // console.log(params);
        try {
            const response = await axios.post(`${config.api.invokeUrl}/visitsvisitor`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            })
                .then(response => {
                    this.setState({ setTickets: response.data });
                    data = response.data;
                    // Convert data to CSV format
                    // const dataForExport = Papa.unparse(response.data);
                    // csvData = dataForExport;
                    console.log(data);

                    // this.setState({ dataInCSV: response.data})
                    // this.exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
                })
                .catch(error => {
                    // Handle errors
                    // this.setState({ error });
                    console.error(error);
                });

            this.setState({ isLoading: false });

            // this.setState({ setTickets: response.data });
            // data = response.data;
            // console.log(data);
            this.setState({ errMsg_region: "block" });
        } catch (error) {
            // this.setState({ error });
            console.log(`An error has occurred: ${error}`);
        }
    };

    getAllVisitsFilter = async (filter) => {

        // "newid": this.state.currentYear.toString() + (this.state.currentMonth + 1).toString(),
        // "buildingname": filter,
        // "country": sCountry
        // id: localStorage.getItem('username'),
        // usid: localStorage.getItem('usersession')


        // "newid": "20234",
        // "buildingname": filter,
        // "country": "South_Africa"

        const params = {
            "newid": this.state.currentYear.toString() + (this.state.currentMonth + 1).toString(),
            "buildingname": filter,
            // "country": sCountry
        };
        try {
            const response = await axios.post(`${config.api.invokeUrl}/visitortemp`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            })
                .then(response => {
                    this.setState({ setTickets: response.data });
                    data = response.data;
                    // Convert data to CSV format
                    // const dataForExport = Papa.unparse(response.data);
                    // csvData = dataForExport;

                    // this.setState({ dataInCSV: response.data})

                    // this.exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
                })
                .catch(error => {
                    // Handle errors
                    console.error(error);
                });
            // this.setState({ dataInCSV: response.data });
            // data = response.data;

            // console.log(this.setTickets);
            this.setState({ errMsg_region: "block" });
        } catch (error) {
            console.log(`An error has occurred: ${error}`);
        }
    };

    componentDidMount() {

        const tmpDate = new Date()
        const tmpEndDate = new Date()
        const tmpStartDate = new Date(tmpDate.setMonth(tmpDate.getMonth() - 1))
        this.setState({ startDate: tmpStartDate });
        startTimeStamp = Date.parse(tmpStartDate).toString();
        this.setState({ setStartTimestamp: startTimeStamp })

        this.setState({ endDate: tmpEndDate });
        endTimeStamp = Date.parse(tmpEndDate).toString();
        this.setState({ setEndTimestamp: endTimeStamp })

        // this.getAllSurveys();

        /////this.getAllVisitsFilter();
        // console.log(this.state.userRole);

        // this.getAllCountries();

        // if (this.state.userRole === "Administrator") {
        //     this.setState({ isAdminRole: true });
        //     // adminDisplay = true;
        // }

        // if (this.state.userRole !== "Administrator") {
        //     // this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) });
        //     this.setState({ countryId: this.removeWhiteSpace(this.state.adminCountry) }, () => {

        //     });
        //     sCountry = this.removeWhiteSpace(this.state.adminCountry);
        //     this.setState({ countryDisabled: true });
        //     console.log(sCountry);
        //     this.getAllSurveys();
        // }
    }

    componentDidCatch(error, errorInfo) {
        //log the error to an error reporting service
        // errorService.log({ error, errorInfo });
    }

    render() {
        const style = {
            control: (base, state) => ({
                ...base,
                borderTop: 'none',
                borderRight: 'none',
                borderLeft: 'none',
                borderBottom: '1px solid #707070',
                boxShadow: 'none',
                '&:hover': {
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                    borderBottom: '1px solid #707070',
                }
            })
        };
        // const selectionRange = {
        //     startDate: new Date(),
        //     endDate: new Date(),
        //     key: 'selection',
        //   }
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.editId
                }
            }} />;
        }
        return (
            <div style={{ overflow: '-moz-hidden-unscrollable' }}>
                <div className="top">
                    <Header></Header>
                </div>

                <div className="left">
                    {this.state.userRole === "Super User" || this.state.userRole === "Administrator" ?
                        (
                            <div>
                                <Link to={`/adminUsers/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />User Administration</button></Link>
                                <Link to={`/employee/`}><button className="menubutton"><img src={admin} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Employee Administration</button></Link>
                                <Link to={`/surveyAdmin/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Administration</button></Link>
                                <Link to={`/surveyresults/`}><button className="menubutton"><img src={chartline} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Survey Results</button></Link>
                                <Link to={`/countryAdmin/`} style={{ display: this.state.isAdminRole ? 'block' : 'none' }}><button className="menubutton"><img src={globew} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Country Administration</button></Link>
                                <Link to={`/regionAdmin/`}><button className="menubutton"><img src={region} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Region Administration</button></Link>
                                <Link to={`/buildingAdmin/`}><button className="menubutton"><img src={enterprise} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Building Administration</button></Link>
                                <Link to={`/rejectionsAdmin/`}><button className="menubutton"><img src={rejectwhite} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Privacy Rejection Report</button></Link>
                            </div>
                        ) : (
                            <span></span>
                        )}
                    <Link to={`/report/`}><button style={{ backgroundColor: '#7FA5D0' }} className="menubutton"><img src={chart} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Visitors Report</button></Link>
                    <Link to={`/`}><button className="menubutton"><img src={logout} style={{ width: '20px', margin: '0px 3px 0px 0px' }} />Logout</button></Link>
                </div>

                <div className="main">
                    <div style={{ margin: '0px 0px 0px 10px' }}>

                        <div className="stickyDiv" >

                        {/* <td><span className="pageTitle">Visitors Report</span></td> */}
                        <div className="d-flex bd-highlight">
                            <div className="p-2 flex-grow-1 bd-highlight pageTitle">Visitors Report</div>

                            <div className="p-2 bd-highlight"><button onClick={() => this.downloadExcel(data)} className="btn btn-secondary" style={{ margin: '0px 0px 0px 10px' }} >Download as Excel</button></div>
                        </div>

                        <div className="d-inline-flex p-2 bd-highlight">
                            <div className="p-2 bd-highlight">From Date :</div>
                            <div className="p-2 bd-highlight">
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(value, e) => this.setStartDate(value, e)}
                                    dateFormat="yyyy-MM-dd"
                                    value={this.state.startDate}
                                    // onSelect={(date) => this.handleStartDateSelect(date)}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    className="form-control form-control-sm"
                                /></div>
                            <div className="p-2 bd-highlight">To Date :</div>
                            <div className="p-2 bd-highlight">
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(value, e) => this.setEndDate(value, e)}
                                    dateFormat="yyyy-MM-dd"
                                    value={this.state.endDate}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    minDate={this.state.startDate}
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        </div>

                        <div style={{ display: this.state.errMsg_region }}>

                        </div>

                        <div className="tableContainer" style={{ margin: '5px 0px 0px 0px' }}>
                            <ErrorBoundary>
                                {this.state.isLoading ? <LoadingSpinner /> :
                                    <PaginationTableComponent
                                        ticketsAll={data}
                                        searched={this.state.searched}
                                        status={this.state.status}
                                    />
                                }
                            </ErrorBoundary>
                        </div>


                    </div>





                </div>


            </div>
        )
    }
}

export default report;